$topNavHeight: 60px;
$leftNavWidth: 260px;
$gutter: 16px;

* {
  //font-family: "Poppins", sans-serif !important;
  font-family: "Roboto", sans-serif !important;
}

.page-wrapper {
  min-width: 1024px;
  //position: absolute;
  // top: $topNavHeight;
  // left: 0;
  // right: 0;
  // bottom: 0;

  .customer-page {
    // margin: 20px;
    margin: 3px 4px;
    background: #fff;
    padding: 20px;
    -webkit-box-shadow: 0 10px 40px 0 #126ad312, 0 2px 9px 0 #126ad30f;
    box-shadow: 0 10px 40px 0 #126ad312, 0 2px 9px 0 #126ad30f;
    border-radius: 0.25rem;
    min-height: calc(100vh - 51px);
  }

  .dashboard-container {
    padding: 35px 18px 18px 35px !important;

    .MuiGrid-item {
      margin: 10px;
      background: #fff;
      -webkit-box-shadow: 0 10px 40px 0 #126ad312, 0 2px 9px 0 #126ad30f;
      box-shadow: 0 10px 40px 0 #126ad312, 0 2px 9px 0 #126ad30f;
      border-radius: 0.25rem;
      padding: 0px;

      // &.chart-queries {
      //   .wise-queries-charts {
      //     //padding-top: 35px;
      //   }
      // }

      .chart-pie {
        .echarts-for-react {
          // margin-top: 50px;
          padding: 0px;
          padding-bottom: 20px;
          padding-top: 20px;
        }
      }

      .chart-pie-large {
        .echarts-for-react {
          margin-top: 30px;
          padding: 0px;
          padding-bottom: 20px;
          padding-top: 20px;
        }
      }

      .chart-title {
        font-weight: 500;
        color: #505050;
        margin-bottom: 0.75rem;
        padding: 10px 10px;
        border-bottom: 1px solid #ddd;
        background: #e7e7e7;

      }

      .echarts-for-react {
        padding: 20px;
      }

      .wise-loading-charts {
        display: flex;
      }

      .wise-queries-charts {
        display: flex;
        padding-top: 10px;
      }

    }

    .MuiGrid-container {
      width: calc(100% + 50px) !important;
    }

    .wise-queries-charts {
      margin: 0 20px 0 20px;

      .echarts-for-react {
        width: 50%;
        padding-right: 0px;
        padding-left: 5px;
      }
    }
  }

  .dashboard-container-map {
    padding: 20px 0px 0px 18px !important;

    .MuiGrid-item {
      margin: 10px;
      background: #fff;
      -webkit-box-shadow: 0 10px 40px 0 #126ad312, 0 2px 9px 0 #126ad30f;
      box-shadow: 0 10px 40px 0 #126ad312, 0 2px 9px 0 #126ad30f;
      border-radius: 0.25rem;
      padding: 0px;
    }

    .MuiGrid-container {
      width: calc(100% + 50px) !important;
    }

    .chart-title {
      font-weight: 500;
      color: #505050;
      padding: 10px 10px;
      border-bottom: 1px solid #ddd;
      background: #e7e7e7;
    }
  }

  .reportBox.customer-page {
    min-height: auto
  }

  .page-wrapper-left {
    position: absolute;
    flex: 1 0 0;
  }

  .page-wrapper-right {
    position: absolute;
    top: 0;
    left: $leftNavWidth;
    right: 0;
    bottom: 0;
    padding: 20px $gutter;
    overflow-y: auto;
    flex: 1 0 0;

  }

  .content-container {
    display: flex;
  }

  .fixed-side-filter {
    position: fixed;
    right: -24px;
    background: #374bbc;
    top: 50%;
    z-index: 999;
    transform: rotateZ(270deg);
    padding: 6px 12px;
    color: #fff;
    border-radius: 4px 4px 0px 0;
    font-size: 12px;
    /* text-transform: uppercase; */
    cursor: pointer;

    i {
      font-size: 10px;
      margin-right: 5px;
    }

    &-active {
      background-color: #f7b817;
      color: #000000;
    }
  }

}

/*Added by Vinod*/
body.menu-collapsed ul.main-menu li.has-submenu:hover .divsubmenu {
  display: block;
  overflow: visible;
  width: 222px !important;
  float: left;
  background: #ffffff;
  padding: 10px;
  margin: -20px 0 0 40px;
  box-shadow: 0px 0px 6px 0px #b1b1b1;
  font-weight: 500;
  border: 1px solid #dbdbdb;
  border-top-width: 0;
}

body.menu-collapsed ul.main-menu li.has-submenu:hover .divsubmenu ul li a {
  font-weight: 400;
  line-height: 24px;
  font-size: 13px;
  padding-left: 10px;
  color: #4a4a4a;
}

body.menu-collapsed ul.main-menu li.has-submenu:hover>a>span {
  background: #ffffff;
  line-height: 24px;
  height: 54px;
  padding: 14px 11px 0px;
  margin: 0px 0 0px 22px;

  /* box-shadow: 0px 0px 6px 0px #b1b1b1; */
  font-weight: 500;
  border: 1px solid #dbdbdb;
  border-bottom-width: 0;
  width: auto !important;
  opacity: 1 !important;
  font-size: inherit !important;
  width: 239px !important;
  font-weight: 600;
  color: #333;
  // font-size: 12px !important;
}

body.menu-collapsed .divsubmenu ul li:hover {
  background: #ebebeb;
}

body.menu-collapsed ul.main-menu>li:hover,
body.menu-collapsed ul.main-menu>li.active {
  width: 100%;
}

body.menu-collapsed ul.main-menu li:hover>a>span {
  background: #ffffff !important;
  line-height: 39px;
  height: 50px;
  padding: 6px 11px;
  margin: 0px 0 0px 22px;
  // box-shadow: 0px 0px 0px 0px #ced4da;
  font-weight: 400;
  border: 1px solid #e9ecef;
  width: auto !important;
  opacity: 1 !important;
  font-size: inherit !important;
  width: 239px !important;
  font-weight: 600;
  color: #555;
  box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.08);

}


body.menu-collapsed header {
  left: 56px;
  position: relative;
}

header {
  left: 18%;
  position: relative;
  width: 100%;
}

.field-container {
  --animate-duration: 1s;
  --animate-delay: 1s;
  --animate-repeat: 1;
  text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeSpeed;
  color: #000;
  box-sizing: border-box;
  font: inherit;
  vertical-align: baseline;
  min-width: 0;
  position: relative;
  padding: 0;
  margin: 0;
  border: 0;
  width: 100%;
  height: 40px;
  top: 4px;
}

.field {
  --animate-duration: 1s;
  --animate-delay: 1s;
  --animate-repeat: 1;
  text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  font-family: inherit;
  line-height: 1;
  margin: 0;
  overflow: visible;
  border: 0;
  width: 100%;
  height: 100%;
  padding: 10px 20px;
  background: white;
  border-radius: 3px;
  box-shadow: -1px 1px 3px #e0e0e0;
  transition: all 0.4s ease;
  font-size: 13px;
}

.icons-container {
  --animate-duration: 1s;
  --animate-delay: 1s;
  --animate-repeat: 1;
  text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeSpeed;
  color: #000;
  box-sizing: border-box;
  font: inherit;
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  position: absolute;
  top: 5px;
  right: 15px;
  width: 35px;
  height: 35px;
  overflow: hidden;
}

.icon-search {
  position: relative;
  left: auto !important;
  right: 10px;
  top: 8px !important;

  &:hover {
    cursor: pointer;
  }
}

.icon-search:after {
  content: "\e034";
}

.field {
  border: 0;
  width: 100%;
  height: 100%;
  padding: 10px 20px;
  background: white;
  border-radius: 3px;
  border: 1px solid #eee;
  transition: all 0.4s ease;
  font-size: 13px;
  box-shadow: none;
}

.field:focus {
  outline: none;
  -webkit-box-shadow: -1px 1px 3px #e0e0e0;
  box-shadow: -1px 1px 3px #e0e0e0;
}

.field:focus+.icons-container .icon-close {
  opacity: 1;
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

.field:focus+.icons-container .icon-search {
  opacity: 0;
  -webkit-transform: translateX(200%);
  transform: translateX(200%);
}

.icon-close {
  position: absolute;
  top: 6px;
  left: 7px;
  width: 75%;
  height: 75%;
  opacity: 0;
  cursor: pointer;
  -webkit-transform: translateX(-200%);
  transform: translateX(-200%);
  border-radius: 50%;
  -webkit-transition: opacity 0.25s ease, -webkit-transform 0.43s cubic-bezier(0.694, 0.048, 0.335, 1);
  transition: opacity 0.25s ease, -webkit-transform 0.43s cubic-bezier(0.694, 0.048, 0.335, 1);
  transition: opacity 0.25s ease, transform 0.43s cubic-bezier(0.694, 0.048, 0.335, 1);
  transition: opacity 0.25s ease, transform 0.43s cubic-bezier(0.694, 0.048, 0.335, 1), -webkit-transform 0.43s cubic-bezier(0.694, 0.048, 0.335, 1);
}

.icon-close:before {
  content: "\e02e";
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  -webkit-transition: opacity 0.2s ease;
  transition: opacity 0.2s ease;
}

.top-icons {
  margin-top: 18px;
  float: right;
  margin-right: 20px;
}

.page-wrapper {
  min-width: 1024px;
  padding-left: 56px;
  float: left;
  width: 100%;
  overflow-y: auto;
  // height: 100vh;
  height: calc(100vh - 40px);
  overflow-x: hidden;
  transition: all 0.2s ease-in-out !important;
}

body.menu-collapsed .customer-page {
  width: calc(100% - 8px);
  float: left;
  margin: 3px 4px;
  // margin: 0;
}

.react-table {
  margin: 5px 0 0 0;
  border-bottom: 1px solid #ddd;
  border: none !important;

  .MuiInputBase-input {
    height: 28px;
    padding: 4px 12px;
    box-sizing: border-box;
  }

  &.react-minitable {
    height: 200px;
  }

  .MuiTablePagination-actions>button {
    padding: 0 5px;
  }

  .MuiTablePagination-root .MuiToolbar-root {
    min-height: 36px;
  }

  &.report-table {
    .MuiDataGrid-footerContainer {
      display: none;
    }
  }

  .MuiDataGrid-footerContainer {
    min-height: 36px;
    font-size: 13px;
    // display: none;

    p {
      font-size: 13px;
      margin: 0;
    }
  }

  .MuiDataGrid-cell {
    >div {
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .MuiDataGrid-columnHeaders {
    border-bottom: none !important;
    border-radius: 0;
    // background: #eee;

  }

  .MuiDataGrid-columnHeader {
    font-size: 12px;
    // background: #f3f3f4;
    font-weight: 500;
    border-bottom: 1px solid #dcdcdd;
    transition: all 0.2s ease-in-out !important;
  }

  .MuiDataGrid-row {
    font-size: 12px;

    b {
      font-weight: 500;
    }
  }

  .MuiDataGrid-cellContent {
    font-size: 12px;
    font-weight: 400;
  }

  .MuiDataGrid-row:nth-child(even) {
    background: #fafafa;
  }

  .MuiDataGrid-cell {
    border-bottom: 0 !important;
    transition: all 0.2s ease-in-out !important;
  }

  .ActionIcons button.MuiButtonBase-root {
    background: none;
    border: none;
  }

  .icon-filter {
    font-size: 9px;
    color: #a19c9c;
  }

}


button.MuiButtonBase-root.MuiButton-root {
  font-size: 12px;
}

a {
  color: #3f51b5;

}

ul.main-menu>li a {
  color: #000000;
}

.divsubmenu>ul>li>li {
  font-size: 13px;
  line-height: 15px;
  padding-left: 10px;
}

.menu-collapsed ul.main-menu {
  overflow: visible;
}

ul.main-menu {
  overflow: auto;
}


.list-header {
  margin: 12px 0 20px !important;
  font-size: 14px;
  font-weight: 500;
  color: #000;
}

html,
body {

  background: #f5f5f5 !important;

}

.page-heading {
  font-size: 15px !important;
  margin: 0 !important;
  line-height: 35px !important;
  //font-family: 'Poppins' !important;
  font-family: "Roboto", sans-serif !important;

  a {
    cursor: pointer;
  }
}

.form-header-area {
  background: #fafafa;
  padding: 10px;
  margin: -20px -20px 10px;
}

.form-heading {
  font-size: 18px !important;
  margin: 0 !important;
  font-weight: 500 !important;
  line-height: 35px !important;
  padding-left: 15px !important;
}

.bread-crumb {
  margin: 0 20px;
  width: 70%;
  margin-left: -25px !important;
}

.MuiTooltip-tooltip {
  font-size: 11px !important;
  font-weight: 400 !important;
  max-width: 500px !important;
  max-height: 400px;
  overflow-y: auto;
}

.exportButtonContainer button.MuiButtonBase-root.MuiButton-root {
  line-height: 1
}

.MuiPagination-root {
  .MuiPagination-ul {
    flex-wrap: nowrap;
  }
}

.menu-collapsed {
  .page-wrapper {
    padding-left: 56px;
  }
}

.page-wrapper {
  padding-left: 15%;
}

.MuiDataGrid-cell:focus-within {
  outline: none !important;
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.selectedCountriesBox {
  position: relative;
  width: 500px;
  height: 11px;

  .selectedCountries {
    position: absolute;
    right: -58px;
    top: 15px;
  }
}


.MuiDrawer-root {
  .multicheck-container {
    .MuiAutocomplete-popupIndicator {
      display: none !important;
    }

    .MuiAutocomplete-popper.MuiAutocomplete-popperDisablePortal {
      margin-top: 10px !important;

      .MuiPaper-root {
        position: relative;
        box-shadow: none;
        border: 1px solid #ccc;
        width: 560px;
        height: auto;

        ul {
          width: 100%;
          max-height: 35vh;

          li {
            width: 33%;
            float: left;
          }
        }
      }
    }

    .MuiAutocomplete-endAdornment {
      top: auto !important;

      .MuiAutocomplete-popupIndicator {
        transform: rotate(360deg) !important;
      }
    }

    .m-t-310 {
      margin-top: 10px
    }
  }

  .filter-container {
    ul {
      li {
        padding: 0px 16px !important
      }
    }
  }
}


.logo-space img {
  width: 33px !important;
  padding: 2px;
  margin-left: 10px;
}


.logo-space img {
  width: 33px !important;
  padding: 2px;
  margin-left: 10px;
  margin-right: 5px;
}

body.menu-collapsed .logo-space img {
  width: 49px !important;
  padding: 10px 3px 0 !important;
  /* height: 40px; */
}

body.menu-collapsed .logo-space img {
  width: 49px !important;
  padding: 10px 3px 0 !important;
  /* height: 40px; */

}

body.menu-collapsed .logo-space img {
  width: 49px !important;
  padding: 10px 3px 0 !important;
  /* height: 40px; */

}

// body.menu-collapsed ul.main-menu {
//   padding-top: 20px !important;
// }

.search-detail {
  .btn-type {
    padding: 2px 12px;
    border: 1px solid #3f51b5;
    border-radius: 2px;
    cursor: pointer;

    &.active {
      background-color: #3f51b5;
      color: white;
    }
  }

  .search-detail-container {
    font-size: 12px;
    overflow-y: auto;

    .search-detail-list {
      border: 1px solid rgb(142, 192, 236);
      margin-bottom: 12px;
      padding: 16px;
    }

    .pdf-link {
      color: blue;
    }

    .name {
      font-size: 20px;
    }

    .base-info {
      display: flex;
      align-items: start;

      >div {
        flex: 1;
      }

      .label {
        color: rgb(189, 184, 184);
      }
    }

    .query {
      .query-label {
        color: rgb(189, 184, 184);
      }
    }

    .response {
      .response-label {
        color: rgb(189, 184, 184);
      }
    }
  }
}

.upload-file-container {
  margin-bottom: 8px;
  position: relative;



  .MuiPaper-rounded.MuiPaper-elevation {
    background-color: transparent;

    .MuiButton-root {
      border-color: #3f51b5;
      color: #3f51b5;
    }
  }

  .MuiBox-root .MuiTypography-root {
    font-size: 14px;

    span.MuiTypography-caption {
      display: none;
    }
  }
}


.react-table-newPagation {
  margin: 5px 0 0 0;
  //border: none !important;
  border: 1px solid #ddd;

  .MuiInputBase-input {
    height: 28px;
    padding: 4px 12px;
    box-sizing: border-box;
  }

  &.react-minitable {
    height: 200px;
  }

  .MuiTablePagination-actions>button {
    padding: 0 5px;
  }

  .MuiTablePagination-root .MuiToolbar-root {
    min-height: 36px;
  }

  .MuiDataGrid-footerContainer {
    justify-content: center;

    p {
      font-size: 13px;
      margin: 0;
    }
  }
}

.host-data {
  position: relative;
  display: flex;
  width: 1232px;
  height: 250px;
  padding-left: 48px;
  overflow-y: scroll;

  & .cuboid {
    z-index: 1;
    width: 127px;
    vertical-align: baseline;
    transform: rotateX(-8deg) rotateY(-8deg);
    transform-style: preserve-3d;

    & div {
      position: absolute;
      bottom: 5px;
      z-index: 3;
      width: 100px;
      cursor: pointer;
    }

    & .front {
      height: 200px;
      background: rgba(163, 218, 255, .2);
      border-top: 6px solid #296AD9;
      transform: translateZ(50px);

      & span {
        display: inline-block;
        width: 80px;
        height: 20px;
        margin: 8px 8px 0 8px;
        background: #F0F8FF;
      }
    }

    & .back {
      height: 200px;
      background: #5E9DF8;
      transform: translateZ(-50px) rotateY(180deg);
    }

    & .left {
      height: 200px;
      background: #8FC3FF;
      transform: rotateY(-90deg) translateZ(50px);
    }

    & .right {
      height: 200px;
      background: #8FC3FF;
      transform: rotateY(90deg) translateZ(50px);
    }

    & .top {
      height: 100px;
      background: #E0F1FF;
      transform: rotateX(90deg) translateX(-19px);
    }
  }
}

.quicksight-stack .Grid-view .MuiGrid-item .MuiPaper-rounded h6 {
  position: absolute !important;
  margin: 0;
  width: 100%;
  bottom: 10px;
  font-size: 12px;
  color: #555;
}

.quicksight-stack .Grid-view .MuiGrid-item .MuiPaper-rounded {
  background: #fafafa;
  box-shadow: none;
  text-align: center;
  min-height: 110px;
  position: relative;
  border: 1px solid #eee;
}


.quicksight-stack {
  .Grid-view {
    .MuiGrid-item {
      .MuiPaper-rounded {
        position: relative !important;
        background: #fafafa;
        box-shadow: none;
        text-align: center;
        min-height: 110px;
        border: 1px solid #eee;

        .quick-sight-pic {
          img {
            width: 60px;
            height: 60px;
            margin-top: 10px;
          }
        }
      }

      h6 {
        margin: 0;
        width: 100%;
        bottom: 10px;
        font-size: 12px;
        color: #555;
        position: absolute;
      }
    }
  }

  .list-view {
    box-shadow: none;
    border: 1px solid #eee;
    background: #fafafa;
    cursor: pointer;

    img {
      width: 25px;
    }

    h6 {
      font-size: 12px;
      color: #555;
    }
  }
}

.MuiDataGrid-panelWrapper {
  .MuiDataGrid-filterForm {
    .MuiInputBase-root.MuiInput-root {
      .MuiInputBase-input {
        text-transform: none
      }
    }
  }
}

.reportTips {
  text-align: end;
  margin-top: 10px;
  margin-bottom: 5px;
  color: #737373;
  font-weight: 500;
}

.echarts-container {
  div>canvas {
    cursor: default;
  }

  background-color: #fff;
}

[role="treeitem"]>div:hover {
  background: transparent;
}

[role="treeitem"]>div:hover {
  background: transparent;
}

// .MuiDataGrid-panel{
//   .MuiDataGrid-filterForm{
//     .MuiDataGrid-filterFormColumnInput{
//       display: none;
//     }
//   }
// }

.text-field-error.MuiFormControl-root {
  .MuiInputBase-root input {
    border-color: #d32f2f;
  }

  .MuiOutlinedInput-root {
    border-color: #d32f2f;
  }
}

.MuiFormHelperText-root.Mui-error {
  margin-left: 0;
}

.MuiTreeView-root.read-only .MuiTreeItem-root {

  .MuiTreeItem-content,
  .MuiFormControlLabel-root {
    cursor: default;
  }

  .Mui-selected {
    background-color: white;

    &:hover {
      background-color: white;
    }

    &.Mui-focused {
      background-color: white;

      &:hover {
        background-color: white;
      }
    }
  }
}


.quick-sight-item {
  margin-top: 10px;
  white-space: normal;
  word-break: break-word;
}

.SnackbarContainer-root .SnackbarItem-message {
  word-break: break-all;
}

.remove-upload-file {
  position: absolute !important;
  right: 0;
  top: 0;
}

.header-icon {
  &.MuiButtonBase-root {
    color: #000;
  }
}

.click-button {
  background: #1473e6;
}

.clickable {
  color: #1a77e6;
}