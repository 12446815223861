  html,
  body {
     font-size: 12px;
    background: rgb(246, 247, 255);
    color: #20214f;
    overflow: hidden;
    height: 100%;
  } 

  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    position: fixed;
    top: 0px;
    left: 0px;
    background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(251,251,251,1) 100%);
    padding: 4px 10px;
    height: 60px;
    display: flex;
    //font-size: calc(10px + 2vmin);
    color: #555555;
    width: 100%;
  }
  
  .App-link {
    color: #61dafb;
  }
  

  .app-body {
    position: absolute;
    top: 60px;
    .left-part {
      width: 400px;
    }
    .right-part {
      width: calc(100% - 400px);
    }
  }
  