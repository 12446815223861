.form-group {

  .MuiFormControl-root .MuiInputBase-root select {
    height: 30px;
    padding: 2px 14px;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    //font-family: "Poppins", sans-serif;
    font-family: "Roboto", sans-serif;
    font-size: 12px;
  }

  .form-select {
    width: 100%;
    height: 27px;
    border-color: red !important;

    .MuiInputBase-root {
      height: 27px;
      padding: 2px 14px;
      width: 100%;
      border-radius: 0.25rem;
      font-family: $font-family;
      font-size: 12px;
      border: 1px solid #ced4da;

      >div {
        padding: 0 !important;
      }
    }

    label {
      position: static;
      font-size: 12px;
      color: #333;
      font-weight: 500;
      max-width: 100% !important;
      width: 100% !important;
      float: left;
      clear: left;
      margin-bottom: 2px;
      transform: none !important;
      text-align: left;
      font-family: $font-family;
    }

    fieldset {
      display: none;
    }
  }

  label {
    position: static;
    font-size: 12px;
    color: #333;
    font-weight: 500;
    // max-width: 100% !important;
    // width: 100% !important;
    float: left;
    clear: left;
    margin-bottom: 2px;
    transform: none !important;
    text-align: left;
    font-family: $font-family;
    line-height: 14px;
  }


  .MuiGrid-container {
    margin-bottom: 15px;
    margin-top: 0;

    // margin-top: 0;
    .MuiGrid-item {
      padding: 0 10px;
    }
  }

  .MuiFormControl-root label.MuiFormLabel-root {
    position: static;
    font-size: 12px;
    color: #333;
    font-weight: 500;
    max-width: 100% !important;
    width: 100% !important;
    float: left;
    clear: left;
    margin-bottom: 2px;
    transform: none !important;
    text-align: left;
    font-family: $font-family;
  }

  .MuiInputLabel-asterisk {
    color: red;
  }

  .MuiAutocomplete-input {
    padding: 2px 10px !important;
  }

  .MuiAutocomplete-tag {
    margin: 0 !important;
  }

  .MuiAutocomplete-inputRoot {
    border-radius: 0 !important;
  }
}

.MuiTabPanel-root .form-group label {
  width: 100% !important;
}

.MuiFormControl-root label.MuiFormLabel-root span.MuiFormLabel-asterisk {
  color: #d71414;
}

.MuiFormControl-root .MuiInputBase-root {
  float: left;
}

.MuiTextField-root {
  float: left;
  width: 100% !important;
}

.MuiFormControl-root .MuiInputBase-root input {
  height: 25px;
  padding: 2px 5px;
  border: 1px solid #ced4da;
  border-radius: 00;
  font-family: $font-family;

  .search-field {
    border: none;
  }

  // font-size: 13px;
}

.MuiFormControl-root .MuiInputBase-root.MuiInputBase-readOnly input {
  background: #fafafa;
}

.MuiFormControl-root .MuiInputBase-root input:focus {
  outline: 1px solid #dddddd;
}

.MuiFormControl-root .MuiOutlinedInput-notchedOutline {
  display: none;
}

.MuiFormControl-root .MuiInputBase-root div.MuiSelect-select {
  height: 23px;
  padding: 3px 10px 3px;
  border: 1px solid #ced4da;
  border-radius: 00;
  line-height: 27px;

  // font-size: 13px;
}

.MuiFormControl-root {
  float: left;
  // width: 100% !important;
}



.custom-dialog {
  .MuiDialogContent-root.form-group .MuiTextField-root {
    margin: 8px;
  }

  .MuiDialogTitle-root {
    padding: 14px;
    font-size: 15px;
    border-bottom: 1px solid #d7d7d7;
  }

  .MuiDialogActions-root button {
    border: 1px solid #72b6eb !important;
    background: white !important;
  }

}







.popup-content {
  float: left;
  padding: 25px !important;
  width: 100%;

  .MuiGrid-container {
    float: left;
    margin-bottom: 10px;
  }

}

/*Grid Checkbox */
.MuiCheckbox-root svg {
  width: 16px;
  height: 16px;
  background-color: transparent;
  border: 1px solid $gray-400; // : 'rgb(67; 67; 67)'
  border-radius: 2px;
  padding: 0;
}

.MuiCheckbox-root svg path {
  display: none !important;

}

.MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg path {
  d: path("M 18 0 H 0 c 0 0 0 0 0 0 v 22 c 0 0 0 0 2 2 h 20 c 0 0 0 0 3 0 V 0 c 0 0 0 0 0 0 Z m -11 17 l -4 -4 l 0 -2.41 L 10 14.17 l 10.59 -8.59 L 21 9 l -10 8 Z");
  //  background-color: $primary-color;
  //  border-color: $primary-color;
  display: block !important;
  fill: $primary-color;
}

.MuiCheckbox-root.Mui-checked {
  &:not(.MuiCheckbox-indeterminate) svg {
    background-color: $white;
    border-color: $primary-color;
  }

  svg {
    background-color: $white;

    path {
      d: path("M 19 0 H 0 c 0 0 0 0 0 0 v 22 c 0 1.1 0.9 2 2 2 h 20 c 1.1 0 2 -0.9 2 -2 V 0 c 0 -1.1 -0.9 -2 -2 -2 Z m 0 14 H 7 v -4 h 12 v 1 Z");
      display: block !important;
      fill: $primary-color;
    }
  }

}


.MuiCheckbox-root.Mui-checked .MuiIconButton-label:after {
  position: absolute;
  display: table;
  border: 1px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) translate(-50%, -50%);
  opacity: 1;
  transition: all .2s cubic-bezier(.12, .4, .29, 1.46) .1s;
  content: "";
  top: 50%;
  left: 39%;
  width: 15.71428571px;
  height: 19.14285714px;
}

.MuiCheckbox-root.MuiCheckbox-indeterminate .MuiIconButton-label:after {
  width: 18px;
  height: 18px;
  background-color: $primary-color;
  transform: none;
  top: 39%;
  border: 0;
}


.form-hilighted-area {
  background: #f9f9f9;
  padding: 16px;
  padding-bottom: 1px;
  margin-bottom: 10px;
  border: 1px dotted #d7d7d7;
}

.MuiDataGrid-filterForm {

  .MuiInputBase-root.MuiInput-root * {
    // font-size: 13px;
    text-transform: capitalize;
  }

  option {
    margin-left: 20px !important;
  }

  .MuiFormLabel-root {
    font-weight: 500;
    color: #333;
  }
}


.MuiFormControlLabel-root .MuiTypography-root {
  font-size: 12px;
}


.MuiCheckbox-root+span.MuiFormControlLabel-label,
.MuiRadio-root+span.MuiFormControlLabel-label {
  font-size: 12px;
}