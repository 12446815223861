@font-face {
    font-family: 'icons';
    src:  url('../fonts/basic-icons.eot?ai44j');
    src:  url('../fonts/basic-icons.eot?ai44j#iefix') format('embedded-opentype'),
      url('../fonts/basic-icons.ttf?ai44j') format('truetype'),
      url('../fonts/basic-icons.woff?ai44j') format('woff'),
      url('../fonts/basic-icons.svg?ai44j#icons') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
  
  [class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icons' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  
  .icon-search21:before {
    content: "\84";
  }
  .icon-file-time1:before {
    content: "\85";
  }
  .icon-refresh-file1:before {
    content: "\86";
  }
  .icon-file-history1:before {
    content: "\87";
  }
  .icon-menu-31:before {
    content: "\e080";
  }
  .icon-home-button1:before {
    content: "\e081";
  }
  .icon-menu6:before {
    content: "\e9fa";
  }
  .icon-account_box:before {
    content: "\e9fb";
  }
  .icon-bell1:before {
    content: "\e9fc";
  }
  .icon-home1:before {
    content: "\e9fd";
  }
  .icon-person_outline:before {
    content: "\e9fe";
  }
  .icon-drive_folder_upload1:before {
    content: "\e9ff";
  }
  .icon-repeat_one:before {
    content: "\ea00";
  }
  .icon-content_copy:before {
    content: "\ea01";
  }
  .icon-important_devices:before {
    content: "\ea02";
  }
  .icon-user4:before {
    content: "\ea03";
  }
  .icon-pie-chart3:before {
    content: "\ea04";
  }
  .icon-search4:before {
    content: "\ea05";
  }
  .icon-food:before {
    content: "\ea06";
  }
  .icon-bell-o:before {
    content: "\ea09";
  }
  .icon-chart-pie1:before {
    content: "\ea0a";
  }
  .icon-drive_folder_upload:before {
    content: "\ea0b";
  }
  .icon-paste1:before {
    content: "\ea0c";
  }
  .icon-files-empty1:before {
    content: "\ea0e";
  }
  .icon-sphere:before {
    content: "\ea0f";
  }
  .icon-star-fill1:before {
    content: "\88";
  }
  .icon-sorting1:before {
    content: "\89";
  }
  .icon-refresh21:before {
    content: "\8a";
  }
  .icon-user-icon1:before {
    content: "\8b";
  }
  .icon-download-file1:before {
    content: "\8c";
  }
  .icon-interface1:before {
    content: "\8d";
  }
  .icon-file-search1:before {
    content: "\8e";
  }
  .icon-sort-down2:before {
    content: "\8f";
  }
  .icon-sort-up1:before {
    content: "\90";
  }
  .icon-sort-down11:before {
    content: "\91";
  }
  .icon-replace1:before {
    content: "\92";
  }
  .icon-user-network1:before {
    content: "\93";
  }
  .icon-folder-network1:before {
    content: "\94";
  }
  .icon-flow-chart31:before {
    content: "\95";
  }
  .icon-flow-chart-dots1:before {
    content: "\96";
  }
  .icon-poll1:before {
    content: "\97";
  }
  .icon-eye31:before {
    content: "\98";
  }
  .icon-calendar-time1:before {
    content: "\99";
  }
  .icon-delete-button-lines1:before {
    content: "\9a";
  }
  .icon-hourglass2:before {
    content: "\9b";
  }
  .icon-right-arrow-fill1:before {
    content: "\9c";
  }
  .icon-left-arrow-fill1:before {
    content: "\9d";
  }
  .icon-down-arrow-fill1:before {
    content: "\9e";
  }
  .icon-up-arrow-fill1:before {
    content: "\9f";
  }
  .icon-session-time1:before {
    content: "\a0";
  }
  .icon-time-correct1:before {
    content: "\a1";
  }
  .icon-settings4:before {
    content: "\a2";
  }
  .icon-settings12:before {
    content: "\a3";
  }
  .icon-collapse1:before {
    content: "\a4";
  }
  .icon-expand1:before {
    content: "\a5";
  }
  .icon-angle-left1:before {
    content: "\a6";
  }
  .icon-angle-right1:before {
    content: "\a7";
  }
  .icon-safe-time1:before {
    content: "\a8";
  }
  .icon-user-history1:before {
    content: "\a9";
  }
  .icon-chart21:before {
    content: "\aa";
  }
  .icon-file-network1:before {
    content: "\ab";
  }
  .icon-flow-tree3:before {
    content: "\ac";
  }
  .icon-chart11:before {
    content: "\ad";
  }
  .icon-flow-tree11:before {
    content: "\ae";
  }
  .icon-chart221:before {
    content: "\af";
  }
  .icon-flow-tree21:before {
    content: "\b0";
  }
  .icon-flow-tree-text1:before {
    content: "\b1";
  }
  .icon-text-file21:before {
    content: "\b2";
  }
  .icon-text-list-file21:before {
    content: "\b3";
  }
  .icon-user31:before {
    content: "\b4";
  }
  .icon-user-round1:before {
    content: "\b5";
  }
  .icon-search23:before {
    content: "\b6";
  }
  .icon-search11:before {
    content: "\b7";
  }
  .icon-search221:before {
    content: "\b8";
  }
  .icon-search31:before {
    content: "\b9";
  }
  .icon-bookmark2:before {
    content: "\ba";
  }
  .icon-bookmark-empty1:before {
    content: "\bb";
  }
  .icon-printer2:before {
    content: "\bc";
  }
  .icon-open-folder2:before {
    content: "\bd";
  }
  .icon-closed-folder1:before {
    content: "\be";
  }
  .icon-open-folder11:before {
    content: "\bf";
  }
  .icon-calendar-peel1:before {
    content: "\c0";
  }
  .icon-menu21:before {
    content: "\c1";
  }
  .icon-chevron-left1:before {
    content: "\c2";
  }
  .icon-caret-up-two1:before {
    content: "\c3";
  }
  .icon-chevron-right2:before {
    content: "\c4";
  }
  .icon-chevron-up1:before {
    content: "\c5";
  }
  .icon-chevron-down2:before {
    content: "\c6";
  }
  .icon-angle-down1:before {
    content: "\c7";
  }
  .icon-left-arrow1:before {
    content: "\c8";
  }
  .icon-right-arrow2:before {
    content: "\c9";
  }
  .icon-down-arrow2:before {
    content: "\ca";
  }
  .icon-medical-tablets1:before {
    content: "\cb";
  }
  .icon-file-time2:before {
    content: "\cc";
  }
  .icon-line-chart-dots2:before {
    content: "\cd";
  }
  .icon-refresh-21:before {
    content: "\ce";
  }
  .icon-line-chart-dots11:before {
    content: "\cf";
  }
  .icon-refresh-file2:before {
    content: "\d0";
  }
  .icon-reverse1:before {
    content: "\d1";
  }
  .icon-filter2:before {
    content: "\d2";
  }
  .icon-text-file3:before {
    content: "\d3";
  }
  .icon-doc-file1:before {
    content: "\d4";
  }
  .icon-folders1:before {
    content: "\d5";
  }
  .icon-text-list-file3:before {
    content: "\d6";
  }
  .icon-file-history2:before {
    content: "\d7";
  }
  .icon-empty-file2:before {
    content: "\d8";
  }
  .icon-code-file1:before {
    content: "\d9";
  }
  .icon-text-file11:before {
    content: "\da";
  }
  .icon-text-list-file11:before {
    content: "\db";
  }
  .icon-empty-file11:before {
    content: "\dc";
  }
  .icon-folder1:before {
    content: "\dd";
  }
  .icon-books2:before {
    content: "\de";
  }
  .icon-books11:before {
    content: "\df";
  }
  .icon-network-folders1:before {
    content: "\e0";
  }
  .icon-calendar21:before {
    content: "\e1";
  }
  .icon-angle-up1:before {
    content: "\e2";
  }
  .icon-caret-left1:before {
    content: "\e3";
  }
  .icon-caret-right1:before {
    content: "\e4";
  }
  .icon-caret-down-two1:before {
    content: "\e5";
  }
  .icon-left-open-big1:before {
    content: "\e082";
  }
  .icon-up-open-big1:before {
    content: "\e083";
  }
  .icon-right-open-big1:before {
    content: "\e084";
  }
  .icon-down-open-big1:before {
    content: "\e085";
  }
  .icon-down-open1:before {
    content: "\e086";
  }
  .icon-left-open1:before {
    content: "\e087";
  }
  .icon-right-open1:before {
    content: "\e088";
  }
  .icon-up-open1:before {
    content: "\e089";
  }
  .icon-arrow-down1:before {
    content: "\e08a";
  }
  .icon-arrow-left1:before {
    content: "\e08b";
  }
  .icon-arrow-right1:before {
    content: "\e08d";
  }
  .icon-arrow-up1:before {
    content: "\e08e";
  }
  .icon-reload31:before {
    content: "\e08f";
  }
  .icon-chevron-up-11:before {
    content: "\e090";
  }
  .icon-chevron-right-11:before {
    content: "\e091";
  }
  .icon-chevron-left-11:before {
    content: "\e092";
  }
  .icon-chevron-down-11:before {
    content: "\e093";
  }
  .icon-arrow-swap1:before {
    content: "\e094";
  }
  .icon-exchange1:before {
    content: "\e095";
  }
  .icon-angle-double-down1:before {
    content: "\e096";
  }
  .icon-download31:before {
    content: "\e097";
  }
  .icon-download-11:before {
    content: "\e098";
  }
  .icon-close-button1:before {
    content: "\e099";
  }
  .icon-close1:before {
    content: "\e09a";
  }
  .icon-close-round1:before {
    content: "\e09b";
  }
  .icon-cross-mark1:before {
    content: "\e09c";
  }
  .icon-cancel1:before {
    content: "\e09d";
  }
  .icon-remove1:before {
    content: "\e09e";
  }
  .icon-lock1:before {
    content: "\e09f";
  }
  .icon-unlock-alt1:before {
    content: "\e0a0";
  }
  .icon-user-outline1:before {
    content: "\e0a1";
  }
  .icon-book1:before {
    content: "\e0a2";
  }
  .icon-content-71:before {
    content: "\e0a3";
  }
  .icon-folder-o1:before {
    content: "\e0a4";
  }
  .icon-folder-open-o1:before {
    content: "\e0a5";
  }
  .icon-folder-open1:before {
    content: "\e0a6";
  }
  .icon-folder-11:before {
    content: "\e0a7";
  }
  .icon-file-close-alt1:before {
    content: "\e0a8";
  }
  .icon-file-open1:before {
    content: "\e0a9";
  }
  .icon-file-close1:before {
    content: "\e0aa";
  }
  .icon-file-alt1:before {
    content: "\e0ab";
  }
  .icon-draw1:before {
    content: "\e0ac";
  }
  .icon-editing1:before {
    content: "\e0ad";
  }
  .icon-edit2:before {
    content: "\e0ae";
  }
  .icon-trash-o1:before {
    content: "\e0af";
  }
  .icon-sort1:before {
    content: "\e0b0";
  }
  .icon-times1:before {
    content: "\e0b1";
  }
  .icon-close-11:before {
    content: "\e0b2";
  }
  .icon-search-12:before {
    content: "\e0b3";
  }
  .icon-search-icon1:before {
    content: "\e0b4";
  }
  .icon-search-1-11:before {
    content: "\e0b5";
  }
  .icon-search-21:before {
    content: "\e0b6";
  }
  .icon-search-find1:before {
    content: "\e0b7";
  }
  .icon-id-card1:before {
    content: "\e0b8";
  }
  .icon-resume1:before {
    content: "\e0b9";
  }
  .icon-plus2:before {
    content: "\e0ba";
  }
  .icon-plus-circle1:before {
    content: "\e0bb";
  }
  .icon-plus-11:before {
    content: "\e0bc";
  }
  .icon-plus-circled1:before {
    content: "\e0bd";
  }
  .icon-plus-square1:before {
    content: "\e0be";
  }
  .icon-menu-32:before {
    content: "\e0bf";
  }
  .icon-list2:before {
    content: "\e0c0";
  }
  .icon-menu-11:before {
    content: "\e0c1";
  }
  .icon-bars21:before {
    content: "\e0c2";
  }
  .icon-content-441:before {
    content: "\e0c3";
  }
  .icon-table1:before {
    content: "\e0c4";
  }
  .icon-add1:before {
    content: "\e0c5";
  }
  .icon-question1:before {
    content: "\e0c6";
  }
  .icon-folder21:before {
    content: "\e0c7";
  }
  .icon-folder-21:before {
    content: "\e0c8";
  }
  .icon-folder-1-11:before {
    content: "\e0c9";
  }
  .icon-pdf-file-format-symbol1:before {
    content: "\e0ca";
  }
  .icon-file-pdf-o21:before {
    content: "\e0cb";
  }
  .icon-file-word-o2:before {
    content: "\e0cc";
  }
  .icon-file-text-o2:before {
    content: "\e0cd";
  }
  .icon-file-powerpoint-o1:before {
    content: "\e0ce";
  }
  .icon-file-image-o2:before {
    content: "\e0cf";
  }
  .icon-files-o1:before {
    content: "\e0d0";
  }
  .icon-file-archive-o21:before {
    content: "\e0d1";
  }
  .icon-file-code-o2:before {
    content: "\e0d2";
  }
  .icon-file-excel-o21:before {
    content: "\e0d3";
  }
  .icon-file-o1:before {
    content: "\e0d4";
  }
  .icon-page-add1:before {
    content: "\e0d5";
  }
  .icon-file-add1:before {
    content: "\e0d6";
  }
  .icon-file-settings1:before {
    content: "\e0d7";
  }
  .icon-align-center1:before {
    content: "\e0d8";
  }
  .icon-files2:before {
    content: "\e0d9";
  }
  .icon-file-code1:before {
    content: "\e0da";
  }
  .icon-file-binary1:before {
    content: "\e0db";
  }
  .icon-file-pdf21:before {
    content: "\e0dc";
  }
  .icon-gallery1:before {
    content: "\e0dd";
  }
  .icon-tool2:before {
    content: "\e0de";
  }
  .icon-paperclip11:before {
    content: "\e0df";
  }
  .icon-content-411:before {
    content: "\e0e0";
  }
  .icon-content-431:before {
    content: "\e0e1";
  }
  .icon-eye-11:before {
    content: "\e0e2";
  }
  .icon-check-mark2:before {
    content: "\e0e3";
  }
  .icon-round-11:before {
    content: "\e0e4";
  }
  .icon-signs1:before {
    content: "\e0e5";
  }
  .icon-lock-11:before {
    content: "\e0e6";
  }
  .icon-unlock2:before {
    content: "\e0e7";
  }
  .icon-lock-21:before {
    content: "\e0e8";
  }
  .icon-unlocked1:before {
    content: "\e0e9";
  }
  .icon-user-11:before {
    content: "\e0ea";
  }
  .icon-man-people-streamline-user1:before {
    content: "\e0eb";
  }
  .icon-power1:before {
    content: "\e0ec";
  }
  .icon-power-11:before {
    content: "\e0ed";
  }
  .icon-log-out1:before {
    content: "\e0ee";
  }
  .icon-arrow-left-11:before {
    content: "\e0ef";
  }
  .icon-arrows-11:before {
    content: "\e0f0";
  }
  .icon-download-21:before {
    content: "\e0f1";
  }
  .icon-arrow-circle-down-line1:before {
    content: "\e0f2";
  }
  .icon-download-1-11:before {
    content: "\e0f3";
  }
  .icon-home-11:before {
    content: "\e0f4";
  }
  .icon-home-house-streamline1:before {
    content: "\e0f5";
  }
  .icon-home-button2:before {
    content: "\e0f6";
  }
  .icon-correct1:before {
    content: "\e0f7";
  }
  .icon-line-spacing:before {
    content: "\ea10";
  }
  .icon-thumbs:before {
    content: "\ea11";
  }
  .icon-share-square-o:before {
    content: "\ea12";
  }
  .icon-mail-forward:before {
    content: "\ea13";
  }
  .icon-share-alternitive:before {
    content: "\ea14";
  }
  .icon-share:before {
    content: "\ea15";
  }
  .icon-share-alt:before {
    content: "\ea16";
  }
  .icon-share1:before {
    content: "\ea17";
  }
  .icon-inbox-check:before {
    content: "\ea18";
  }
  .icon-document-checked:before {
    content: "\ea19";
  }
  .icon-edit3:before {
    content: "\ea1a";
  }
  .icon-file2:before {
    content: "\ea1b";
  }
  .icon-user-check2:before {
    content: "\ea1c";
  }
  .icon-googleplus:before {
    content: "\ea1d";
  }
  .icon-comments:before {
    content: "\ea1e";
  }
  .icon-user-add-outline:before {
    content: "\ea1f";
  }
  .icon-user-delete-outline:before {
    content: "\ea20";
  }
  .icon-user-outline2:before {
    content: "\ea21";
  }
  .icon-plus-outline:before {
    content: "\ea22";
  }
  .icon-user-delete:before {
    content: "\ea23";
  }
  .icon-document-add:before {
    content: "\ea24";
  }
  .icon-user-add1:before {
    content: "\ea25";
  }
  .icon-folder-add:before {
    content: "\ea26";
  }
  .icon-plus1:before {
    content: "\ea27";
  }
  .icon-user5:before {
    content: "\ea28";
  }
  .icon-upload-outline:before {
    content: "\ea29";
  }
  .icon-drive_folder_upload2:before {
    content: "\ea2a";
  }
  .icon-create_new_folder:before {
    content: "\ea2b";
  }
  .icon-edit11:before {
    content: "\ea2c";
  }
  .icon-folder-minus1:before {
    content: "\ea2e";
  }
  .icon-folder-plus1:before {
    content: "\ea2f";
  }
  .icon-folder_shared:before {
    content: "\ea30";
  }
  .icon-screen_share:before {
    content: "\ea31";
  }
  .icon-share2:before {
    content: "\ea32";
  }
  .icon-playlist_add_check:before {
    content: "\ea33";
  }
  .icon-post_add:before {
    content: "\ea34";
  }
  .icon-person_outline1:before {
    content: "\ea35";
  }
  .icon-person_add:before {
    content: "\ea36";
  }
  .icon-group_add1:before {
    content: "\ea37";
  }
  .icon-add_link:before {
    content: "\ea38";
  }
  .icon-playlist_add_check1:before {
    content: "\ea39";
  }
  .icon-playlist_add:before {
    content: "\ea3a";
  }
  .icon-chat_bubble_outline:before {
    content: "\ea3b";
  }
  .icon-chat1:before {
    content: "\ea3c";
  }
  .icon-user-x1:before {
    content: "\ea3d";
  }
  .icon-file-plus1:before {
    content: "\ea3e";
  }
  .icon-plus-square2:before {
    content: "\ea3f";
  }
  .icon-plus-circle2:before {
    content: "\ea40";
  }
  .icon-plus11:before {
    content: "\ea41";
  }
  .icon-users2:before {
    content: "\ea42";
  }
  .icon-user-minus1:before {
    content: "\ea43";
  }
  .icon-user-plus2:before {
    content: "\ea44";
  }
  .icon-user12:before {
    content: "\ea45";
  }
  .icon-folder-plus11:before {
    content: "\ea46";
  }
  .icon-write1:before {
    content: "\ea47";
  }
  .icon-explore1:before {
    content: "\ea48";
  }
  .icon-at1:before {
    content: "\ea49";
  }
  .icon-user21:before {
    content: "\ea4a";
  }
  .icon-hash1:before {
    content: "\ea4b";
  }
  .icon-office1:before {
    content: "\ea4c";
  }
  .icon-download1:before {
    content: "\ea4d";
  }
  .icon-email21:before {
    content: "\ea4e";
  }
  .icon-call_split1:before {
    content: "\ea4f";
  }
  .icon-event_note1:before {
    content: "\ea50";
  }
  .icon-pie-chart21:before {
    content: "\ea51";
  }
  .icon-hash21:before {
    content: "\ea52";
  }
  .icon-eye4:before {
    content: "\ea53";
  }
  .icon-building-o1:before {
    content: "\ea54";
  }
  .icon-view-tile1:before {
    content: "\ea55";
  }
  .icon-view-list1:before {
    content: "\ea56";
  }
  .icon-view-carousel1:before {
    content: "\ea58";
  }
  .icon-library3:before {
    content: "\ea59";
  }
  .icon-user-solid-circle1:before {
    content: "\ea5a";
  }
  .icon-user-add11:before {
    content: "\ea5b";
  }
  .icon-user211:before {
    content: "\ea5c";
  }
  .icon-text-box1:before {
    content: "\ea5d";
  }
  .icon-chart-pie2:before {
    content: "\ea5e";
  }
  .icon-view-tile21:before {
    content: "\ea5f";
  }
  .icon-mail21:before {
    content: "\ea60";
  }
  .icon-mail-envelope-closed1:before {
    content: "\ea61";
  }
  .icon-mail-envelope-closed21:before {
    content: "\ea62";
  }
  .icon-inbox-upload21:before {
    content: "\ea63";
  }
  .icon-inbox-upload31:before {
    content: "\ea64";
  }
  .icon-inbox-document-text1:before {
    content: "\ea65";
  }
  .icon-inbox-document-text21:before {
    content: "\ea66";
  }
  .icon-inbox-document1:before {
    content: "\ea67";
  }
  .icon-inbox-download1:before {
    content: "\ea68";
  }
  .icon-inbox-upload1:before {
    content: "\ea69";
  }
  .icon-chart4:before {
    content: "\ea6a";
  }
  .icon-site-map1:before {
    content: "\ea6b";
  }
  .icon-alarm-clock1:before {
    content: "\ea6c";
  }
  .icon-justice1:before {
    content: "\ea6d";
  }
  .icon-chat2:before {
    content: "\ea6f";
  }
  .icon-retweet1:before {
    content: "\ea70";
  }
  .icon-resize1:before {
    content: "\ea71";
  }
  .icon-upload21:before {
    content: "\ea72";
  }
  .icon-download41:before {
    content: "\ea73";
  }
  .icon-archive21:before {
    content: "\ea74";
  }
  .icon-arrow-minimise1:before {
    content: "\ea75";
  }
  .icon-vpn_key1:before {
    content: "\ea76";
  }
  .icon-group_add11:before {
    content: "\ea77";
  }
  .icon-account_balance1:before {
    content: "\ea78";
  }
  .icon-question_answer1:before {
    content: "\ea79";
  }
  .icon-compare_arrows1:before {
    content: "\ea7a";
  }
  .icon-crop1:before {
    content: "\ea7b";
  }
  .icon-edit-copy1:before {
    content: "\ea7c";
  }
  .icon-folder-download1:before {
    content: "\ea7d";
  }
  .icon-folder-upload1:before {
    content: "\ea7e";
  }
  .icon-notifications-outline1:before {
    content: "\ea7f";
  }
  .icon-conversation1:before {
    content: "\ea80";
  }
  .icon-stats1:before {
    content: "\ea81";
  }
  .icon-stats21:before {
    content: "\ea82";
  }
  .icon-loop-alt41:before {
    content: "\ea83";
  }
  .icon-image2:before {
    content: "\ea84";
  }
  .icon-library21:before {
    content: "\ea85";
  }
  .icon-file-text3:before {
    content: "\ea86";
  }
  .icon-profile21:before {
    content: "\ea87";
  }
  .icon-file-empty1:before {
    content: "\ea88";
  }
  .icon-files-empty11:before {
    content: "\ea89";
  }
  .icon-file-text21:before {
    content: "\ea8a";
  }
  .icon-file-picture1:before {
    content: "\ea8b";
  }
  .icon-clone1:before {
    content: "\ea8c";
  }
  .icon-file-archive-o1:before {
    content: "\ea8d";
  }
  .icon-file-zip1:before {
    content: "\ea8e";
  }
  .icon-copy2:before {
    content: "\ea8f";
  }
  .icon-paste2:before {
    content: "\ea90";
  }
  .icon-arrows-h1:before {
    content: "\ea91";
  }
  .icon-television1:before {
    content: "\ea92";
  }
  .icon-file-pdf-o3:before {
    content: "\ea93";
  }
  .icon-file-excel-o3:before {
    content: "\ea94";
  }
  .icon-data-viewer1:before {
    content: "\ea95";
  }
  .icon-mail11:before {
    content: "\ea96";
  }
  .icon-history1:before {
    content: "\ea97";
  }
  .icon-users11:before {
    content: "\ea98";
  }
  .icon-icon-import1:before {
    content: "\ea99";
  }
  .icon-alarm1:before {
    content: "\ea9a";
  }
  .icon-in-alt1:before {
    content: "\ea9b";
  }
  .icon-stopwatch1:before {
    content: "\ea9c";
  }
  .icon-calendar3:before {
    content: "\ea9d";
  }
  .icon-file-plus11:before {
    content: "\ea9e";
  }
  .icon-menu11:before {
    content: "\ea9f";
  }
  .icon-display1:before {
    content: "\eaa0";
  }
  .icon-menu31:before {
    content: "\eaa1";
  }
  .icon-download21:before {
    content: "\eaa2";
  }
  .icon-upload1:before {
    content: "\eaa3";
  }
  .icon-download221:before {
    content: "\eaa4";
  }
  .icon-upload31:before {
    content: "\eaa5";
  }
  .icon-enlarge21:before {
    content: "\eaa6";
  }
  .icon-shrink21:before {
    content: "\eaa7";
  }
  .icon-cog1:before {
    content: "\eaa8";
  }
  .icon-ban:before {
    content: "\eaa9";
  }
  .icon-file-audio-o:before {
    content: "\eaaa";
  }
  .icon-file-movie-o:before {
    content: "\eaab";
  }
  .icon-film:before {
    content: "\eaac";
  }
  .icon-pie-chart4:before {
    content: "\eaad";
  }
  .icon-ondemand_video:before {
    content: "\eaae";
  }
  .icon-music_video:before {
    content: "\eaaf";
  }
  .icon-music-playlist:before {
    content: "\eab0";
  }
  .icon-file-music:before {
    content: "\eab1";
  }
  .icon-trainings:before {
    content: "\eab2";
  }
  .icon-expand2:before {
    content: "\eab3";
  }
  .icon-documents1:before {
    content: "\eab4";
  }
  .icon-document:before {
    content: "\eab5";
  }
  .icon-document-certificate:before {
    content: "\eab6";
  }
  .icon-document-graph:before {
    content: "\eab7";
  }
  .icon-document-table:before {
    content: "\eab8";
  }
  .icon-document-diagrams:before {
    content: "\eab9";
  }
  .icon-document-upload:before {
    content: "\eaba";
  }
  .icon-document-download:before {
    content: "\eabb";
  }
  .icon-news:before {
    content: "\eabc";
  }
  .icon-paperplane1:before {
    content: "\eabd";
  }
  .icon-briefcase2:before {
    content: "\eabe";
  }
  .icon-briefcase11:before {
    content: "\eabf";
  }
  .icon-menu_open:before {
    content: "\eac0";
  }
  .icon-menu12:before {
    content: "\eac1";
  }
  .icon-push_pin:before {
    content: "\eac2";
  }
  .icon-comments-o:before {
    content: "\eac3";
  }
  .icon-envelope-o:before {
    content: "\eac4";
  }
  .icon-files-empty2:before {
    content: "\eac5";
  }
  .icon-file-empty2:before {
    content: "\eac6";
  }
  .icon-newspaper:before {
    content: "\eac7";
  }
  .icon-list1:before {
    content: "\eac8";
  }
  .icon-menu7:before {
    content: "\eac9";
  }
  .icon-list-add:before {
    content: "\eaca";
  }
  .icon-clipboard2:before {
    content: "\eacb";
  }
  .icon-news-paper:before {
    content: "\eacc";
  }
  .icon-pin1:before {
    content: "\eacd";
  }
  .icon-filter11:before {
    content: "\eace";
  }
  .icon-external:before {
    content: "\eacf";
  }
  .icon-menu311:before {
    content: "\ead0";
  }
  .icon-menu41:before {
    content: "\ead1";
  }
  .icon-flag3:before {
    content: "\ead2";
  }
  .icon-eye21:before {
    content: "\ead3";
  }
  .icon-warning1:before {
    content: "\ead4";
  }
  .icon-notification21:before {
    content: "\ead5";
  }
  .icon-cancel-circle1:before {
    content: "\ead6";
  }
  .icon-loop21:before {
    content: "\ead7";
  }
  .icon-crop21:before {
    content: "\ead8";
  }
  .icon-pagebreak1:before {
    content: "\ead9";
  }
  .icon-file-pdf1:before {
    content: "\eae0";
  }
  .icon-file-word1:before {
    content: "\eae3";
  }
  .icon-file-excel1:before {
    content: "\eae4";
  }
  .icon-refresh22:before {
    content: "\e6";
  }
  .icon-user-icon2:before {
    content: "\e7";
  }
  .icon-file-search2:before {
    content: "\e8";
  }
  .icon-flow-chart32:before {
    content: "\e9";
  }
  .icon-flow-chart-dots2:before {
    content: "\ea";
  }
  .icon-settings21:before {
    content: "\eb";
  }
  .icon-safe-time2:before {
    content: "\ec";
  }
  .icon-flow-tree4:before {
    content: "\ed";
  }
  .icon-flow-tree12:before {
    content: "\ee";
  }
  .icon-flow-tree22:before {
    content: "\ef";
  }
  .icon-text-file22:before {
    content: "\f0";
  }
  .icon-search24:before {
    content: "\f1";
  }
  .icon-search12:before {
    content: "\f2";
  }
  .icon-file-time3:before {
    content: "\f3";
  }
  .icon-line-chart-dots3:before {
    content: "\f4";
  }
  .icon-refresh-file3:before {
    content: "\f5";
  }
  .icon-text-list-file4:before {
    content: "\f6";
  }
  .icon-file-history3:before {
    content: "\f7";
  }
  .icon-empty-file3:before {
    content: "\f8";
  }
  .icon-calendar22:before {
    content: "\f9";
  }
  .icon-caret-left2:before {
    content: "\fa";
  }
  .icon-caret-right2:before {
    content: "\fb";
  }
  .icon-down-open2:before {
    content: "\e0f8";
  }
  .icon-left-open2:before {
    content: "\e0f9";
  }
  .icon-right-open2:before {
    content: "\e0fa";
  }
  .icon-up-open2:before {
    content: "\e0fb";
  }
  .icon-close2:before {
    content: "\e0fc";
  }
  .icon-close-round2:before {
    content: "\e0fd";
  }
  .icon-cross-mark2:before {
    content: "\e0fe";
  }
  .icon-cancel2:before {
    content: "\e0ff";
  }
  .icon-search-icon2:before {
    content: "\e100";
  }
  .icon-search-22:before {
    content: "\e101";
  }
  .icon-folder-1-12:before {
    content: "\e102";
  }
  .icon-file-text-o3:before {
    content: "\e103";
  }
  .icon-files-o2:before {
    content: "\e104";
  }
  .icon-file-o2:before {
    content: "\e105";
  }
  .icon-file-settings2:before {
    content: "\e106";
  }
  .icon-content-412:before {
    content: "\e107";
  }
  .icon-check-mark3:before {
    content: "\e108";
  }
  .icon-round-12:before {
    content: "\e109";
  }
  .icon-signs2:before {
    content: "\e10a";
  }
  .icon-log-out2:before {
    content: "\e10b";
  }
  .icon-setting1:before {
    content: "\eada";
  }
  .icon-leadership:before {
    content: "\eadb";
  }
  .icon-profile:before {
    content: "\eadc";
  }
  .icon-user6:before {
    content: "\eadd";
  }
  .icon-dots-menu:before {
    content: "\eade";
  }
  .icon-menu8:before {
    content: "\eae5";
  }
  .icon-arrow1:before {
    content: "\eae6";
  }
  .icon-left-right:before {
    content: "\eae7";
  }
  .icon-left-and-right:before {
    content: "\eae8";
  }
  .icon-money:before {
    content: "\eae9";
  }
  .icon-exchange2:before {
    content: "\eaea";
  }
  .icon-notification1:before {
    content: "\eaeb";
  }
  .icon-notification-1:before {
    content: "\eaec";
  }
  .icon-theme:before {
    content: "\eaed";
  }
  .icon-paint:before {
    content: "\eaee";
  }
  .icon-theme-1:before {
    content: "\eaef";
  }
  .icon-half-moon:before {
    content: "\eaf0";
  }
  .icon-layer:before {
    content: "\eaf1";
  }
  .icon-layers1:before {
    content: "\eaf2";
  }
  .icon-settings5:before {
    content: "\eaf3";
  }
  .icon-setting-lines:before {
    content: "\eaf4";
  }
  .icon-cogwheel:before {
    content: "\eaf5";
  }
  .icon-profile-1:before {
    content: "\eaf6";
  }
  .icon-setting-11:before {
    content: "\eaf7";
  }
  .icon-online-library:before {
    content: "\eaf8";
  }
  .icon-books3:before {
    content: "\eaf9";
  }
  .icon-loop-alt3:before {
    content: "\eafa";
  }
  .icon-arrow-right2:before {
    content: "\eafb";
  }
  .icon-arrow-left2:before {
    content: "\eafc";
  }
  .icon-arrow-down2:before {
    content: "\eafd";
  }
  .icon-arrow-up2:before {
    content: "\eafe";
  }
  .icon-dots-three-vertical:before {
    content: "\eaff";
  }
  .icon-dots-three-horizontal:before {
    content: "\eb00";
  }
  .icon-arrow_downward:before {
    content: "\eb01";
  }
  .icon-arrow_upward:before {
    content: "\eb02";
  }
  .icon-arrow_forward:before {
    content: "\eb03";
  }
  .icon-arrow_back:before {
    content: "\eb04";
  }
  .icon-zoom_out_map:before {
    content: "\eb05";
  }
  .icon-gps_not_fixed:before {
    content: "\eb06";
  }
  .icon-label_outline:before {
    content: "\eb07";
  }
  .icon-location-outline:before {
    content: "\eb08";
  }
  .icon-map-pin1:before {
    content: "\eb09";
  }
  .icon-globe1:before {
    content: "\eb0a";
  }
  .icon-bell2:before {
    content: "\eb0b";
  }
  .icon-settings13:before {
    content: "\eb0c";
  }
  .icon-unlock3:before {
    content: "\eb0d";
  }
  .icon-lock2:before {
    content: "\eb0e";
  }
  .icon-location1:before {
    content: "\eb0f";
  }
  .icon-map1:before {
    content: "\eb10";
  }
  .icon-mail-envelope-open:before {
    content: "\eb11";
  }
  .icon-line-spacing1:before {
    content: "\eb12";
  }
  .icon-thumbs1:before {
    content: "\eb13";
  }
  .icon-edit12:before {
    content: "\eb14";
  }
  .icon-person_outline2:before {
    content: "\eb15";
  }
  .icon-plus-square3:before {
    content: "\eb16";
  }
  .icon-plus-circle3:before {
    content: "\eb17";
  }
  .icon-users3:before {
    content: "\eb18";
  }
  .icon-user13:before {
    content: "\eb19";
  }
  .icon-write11:before {
    content: "\eb1a";
  }
  .icon-share21:before {
    content: "\eb1b";
  }
  .icon-chat_bubble_outline1:before {
    content: "\eb1c";
  }
  .icon-email22:before {
    content: "\eb1d";
  }
  .icon-bot:before {
    content: "\eb1e";
  }
  .icon-robot:before {
    content: "\eb1f";
  }
  .icon-pie-chart22:before {
    content: "\eb20";
  }
  .icon-hash22:before {
    content: "\eb21";
  }
  .icon-moon-o:before {
    content: "\eb22";
  }
  .icon-bell-o1:before {
    content: "\eb23";
  }
  .icon-view-tile3:before {
    content: "\eb24";
  }
  .icon-envelope-open-o:before {
    content: "\eb25";
  }
  .icon-view-carousel2:before {
    content: "\eb26";
  }
  .icon-view-tile22:before {
    content: "\eb27";
  }
  .icon-file-plus12:before {
    content: "\eb28";
  }
  .icon-menu111:before {
    content: "\eb29";
  }
  .icon-menu32:before {
    content: "\eb2a";
  }
  .icon-news1:before {
    content: "\eb2b";
  }
  .icon-briefcase12:before {
    content: "\eb2c";
  }
  .icon-envelope-o1:before {
    content: "\eb2d";
  }
  .icon-newspaper1:before {
    content: "\eb2e";
  }
  .icon-clipboard3:before {
    content: "\eb2f";
  }
  .icon-news-paper1:before {
    content: "\eb30";
  }
  .icon-notification22:before {
    content: "\eb31";
  }
  .icon-arrow-up-left2:before {
    content: "\eb32";
  }
  .icon-arrow-up21:before {
    content: "\eb33";
  }
  .icon-arrow-up-right2:before {
    content: "\eb34";
  }
  .icon-arrow-right21:before {
    content: "\eb35";
  }
  .icon-arrow-down-right2:before {
    content: "\eb36";
  }
  .icon-arrow-down21:before {
    content: "\eb37";
  }
  .icon-arrow-down-left2:before {
    content: "\eb38";
  }
  .icon-arrow-left21:before {
    content: "\eb39";
  }
  .icon-refresh23:before {
    content: "\fc";
  }
  .icon-user-icon3:before {
    content: "\fd";
  }
  .icon-file-search3:before {
    content: "\fe";
  }
  .icon-flow-chart33:before {
    content: "\ff";
  }
  .icon-flow-chart-dots3:before {
    content: "\100";
  }
  .icon-settings22:before {
    content: "\101";
  }
  .icon-safe-time3:before {
    content: "\102";
  }
  .icon-flow-tree5:before {
    content: "\103";
  }
  .icon-flow-tree13:before {
    content: "\104";
  }
  .icon-flow-tree23:before {
    content: "\105";
  }
  .icon-text-file23:before {
    content: "\106";
  }
  .icon-search25:before {
    content: "\107";
  }
  .icon-search13:before {
    content: "\108";
  }
  .icon-file-time4:before {
    content: "\109";
  }
  .icon-line-chart-dots4:before {
    content: "\10a";
  }
  .icon-refresh-file4:before {
    content: "\10b";
  }
  .icon-text-list-file5:before {
    content: "\10c";
  }
  .icon-file-history4:before {
    content: "\10d";
  }
  .icon-empty-file4:before {
    content: "\10e";
  }
  .icon-calendar23:before {
    content: "\10f";
  }
  .icon-caret-left3:before {
    content: "\110";
  }
  .icon-caret-right3:before {
    content: "\111";
  }
  .icon-down-open3:before {
    content: "\e10c";
  }
  .icon-left-open3:before {
    content: "\e10d";
  }
  .icon-right-open3:before {
    content: "\e10e";
  }
  .icon-up-open3:before {
    content: "\e10f";
  }
  .icon-close3:before {
    content: "\e110";
  }
  .icon-close-round3:before {
    content: "\e111";
  }
  .icon-cross-mark3:before {
    content: "\e112";
  }
  .icon-cancel3:before {
    content: "\e113";
  }
  .icon-search-icon3:before {
    content: "\e114";
  }
  .icon-search-23:before {
    content: "\e115";
  }
  .icon-folder-1-13:before {
    content: "\e116";
  }
  .icon-file-text-o4:before {
    content: "\e117";
  }
  .icon-files-o3:before {
    content: "\e118";
  }
  .icon-file-o3:before {
    content: "\e119";
  }
  .icon-file-settings3:before {
    content: "\e11a";
  }
  .icon-content-413:before {
    content: "\e11b";
  }
  .icon-check-mark4:before {
    content: "\e11c";
  }
  .icon-round-13:before {
    content: "\e11d";
  }
  .icon-signs3:before {
    content: "\e11e";
  }
  .icon-log-out3:before {
    content: "\e11f";
  }
  .icon-setting2:before {
    content: "\eb3a";
  }
  .icon-leadership1:before {
    content: "\eb3b";
  }
  .icon-profile1:before {
    content: "\eb3c";
  }
  .icon-user7:before {
    content: "\eb3d";
  }
  .icon-dots-menu1:before {
    content: "\eb3e";
  }
  .icon-menu9:before {
    content: "\eb3f";
  }
  .icon-arrow2:before {
    content: "\eb40";
  }
  .icon-left-right1:before {
    content: "\eb41";
  }
  .icon-left-and-right1:before {
    content: "\eb42";
  }
  .icon-money1:before {
    content: "\eb43";
  }
  .icon-exchange3:before {
    content: "\eb44";
  }
  .icon-notification3:before {
    content: "\eb45";
  }
  .icon-notification-11:before {
    content: "\eb46";
  }
  .icon-theme1:before {
    content: "\eb47";
  }
  .icon-paint1:before {
    content: "\eb48";
  }
  .icon-theme-11:before {
    content: "\eb49";
  }
  .icon-half-moon1:before {
    content: "\eb4a";
  }
  .icon-layer1:before {
    content: "\eb4b";
  }
  .icon-layers2:before {
    content: "\eb4c";
  }
  .icon-settings6:before {
    content: "\eb4d";
  }
  .icon-setting-lines1:before {
    content: "\eb4e";
  }
  .icon-cogwheel1:before {
    content: "\eb4f";
  }
  .icon-profile-11:before {
    content: "\eb50";
  }
  .icon-setting-12:before {
    content: "\eb51";
  }
  .icon-online-library1:before {
    content: "\eb52";
  }
  .icon-books4:before {
    content: "\eb53";
  }
  .icon-loop-alt31:before {
    content: "\eb54";
  }
  .icon-arrow-right3:before {
    content: "\eb55";
  }
  .icon-arrow-left3:before {
    content: "\eb56";
  }
  .icon-arrow-down3:before {
    content: "\eb57";
  }
  .icon-arrow-up3:before {
    content: "\eb58";
  }
  .icon-dots-three-vertical1:before {
    content: "\eb59";
  }
  .icon-dots-three-horizontal1:before {
    content: "\eb5a";
  }
  .icon-arrow_downward1:before {
    content: "\eb5b";
  }
  .icon-arrow_upward1:before {
    content: "\eb5c";
  }
  .icon-arrow_forward1:before {
    content: "\eb5d";
  }
  .icon-arrow_back1:before {
    content: "\eb5e";
  }
  .icon-zoom_out_map1:before {
    content: "\eb5f";
  }
  .icon-gps_not_fixed1:before {
    content: "\eb60";
  }
  .icon-label_outline1:before {
    content: "\eb61";
  }
  .icon-location-outline1:before {
    content: "\eb62";
  }
  .icon-map-pin2:before {
    content: "\eb63";
  }
  .icon-globe2:before {
    content: "\eb64";
  }
  .icon-bell3:before {
    content: "\eb65";
  }
  .icon-settings14:before {
    content: "\eb66";
  }
  .icon-unlock4:before {
    content: "\eb67";
  }
  .icon-lock3:before {
    content: "\eb68";
  }
  .icon-location11:before {
    content: "\eb69";
  }
  .icon-map2:before {
    content: "\eb6a";
  }
  .icon-mail-envelope-open1:before {
    content: "\eb6b";
  }
  .icon-line-spacing2:before {
    content: "\eb6c";
  }
  .icon-thumbs2:before {
    content: "\eb6d";
  }
  .icon-edit13:before {
    content: "\eb6e";
  }
  .icon-person_outline3:before {
    content: "\eb6f";
  }
  .icon-plus-square4:before {
    content: "\eb70";
  }
  .icon-plus-circle4:before {
    content: "\eb71";
  }
  .icon-users4:before {
    content: "\eb72";
  }
  .icon-user14:before {
    content: "\eb73";
  }
  .icon-write12:before {
    content: "\eb74";
  }
  .icon-share22:before {
    content: "\eb75";
  }
  .icon-chat_bubble_outline2:before {
    content: "\eb76";
  }
  .icon-email23:before {
    content: "\eb77";
  }
  .icon-bot1:before {
    content: "\eb78";
  }
  .icon-robot1:before {
    content: "\eb79";
  }
  .icon-pie-chart23:before {
    content: "\eb7a";
  }
  .icon-hash23:before {
    content: "\eb7b";
  }
  .icon-moon-o1:before {
    content: "\eb7c";
  }
  .icon-bell-o2:before {
    content: "\eb7d";
  }
  .icon-view-tile4:before {
    content: "\eb7e";
  }
  .icon-envelope-open-o1:before {
    content: "\eb7f";
  }
  .icon-view-carousel3:before {
    content: "\eb80";
  }
  .icon-stats3:before {
    content: "\eb81";
  }
  .icon-data-analytics:before {
    content: "\eb82";
  }
  .icon-hand-gear:before {
    content: "\eb83";
  }
  .icon-paper:before {
    content: "\eb84";
  }
  .icon-email:before {
    content: "\eb85";
  }
  .icon-computer:before {
    content: "\eb86";
  }
  .icon-view-tile23:before {
    content: "\eb87";
  }
  .icon-access:before {
    content: "\eb88";
  }
  .icon-chat3:before {
    content: "\eb89";
  }
  .icon-help:before {
    content: "\eb8a";
  }
  .icon-technical-support:before {
    content: "\eb8b";
  }
  .icon-bot-2:before {
    content: "\eb8c";
  }
  .icon-robot11:before {
    content: "\eb8d";
  }
  .icon-bot11:before {
    content: "\eb8e";
  }
  .icon-file-plus13:before {
    content: "\eb8f";
  }
  .icon-menu112:before {
    content: "\eb90";
  }
  .icon-menu33:before {
    content: "\eb91";
  }
  .icon-news2:before {
    content: "\eb92";
  }
  .icon-briefcase13:before {
    content: "\eb93";
  }
  .icon-envelope-o2:before {
    content: "\eb94";
  }
  .icon-newspaper2:before {
    content: "\eb95";
  }
  .icon-clipboard4:before {
    content: "\eb96";
  }
  .icon-news-paper2:before {
    content: "\eb97";
  }
  .icon-notification23:before {
    content: "\eb98";
  }
  .icon-arrow-up-left21:before {
    content: "\eb99";
  }
  .icon-arrow-up22:before {
    content: "\eb9a";
  }
  .icon-arrow-up-right21:before {
    content: "\eb9b";
  }
  .icon-arrow-right22:before {
    content: "\eb9c";
  }
  .icon-arrow-down-right21:before {
    content: "\eb9d";
  }
  .icon-arrow-down22:before {
    content: "\eb9e";
  }
  .icon-arrow-down-left21:before {
    content: "\eb9f";
  }
  .icon-arrow-left22:before {
    content: "\eba0";
  }
  .icon-alert:before {
    content: "\eba1";
  }
  .icon-index:before {
    content: "\eba2";
  }
  .icon-search-11:before {
    content: "\eba3";
  }
  .icon-research:before {
    content: "\eba4";
  }
  .icon-information:before {
    content: "\eba5";
  }
  .icon-preview:before {
    content: "\eba6";
  }
  .icon-all-directions:before {
    content: "\eba7";
  }
  .icon-group:before {
    content: "\eba8";
  }
  .icon-setting-1:before {
    content: "\eba9";
  }
  .icon-setting:before {
    content: "\ebaa";
  }
  .icon-layout:before {
    content: "\ebab";
  }
  .icon-combine:before {
    content: "\ebac";
  }
  .icon-drag:before {
    content: "\ebad";
  }
  .icon-flag1:before {
    content: "\ebae";
  }
  .icon-link:before {
    content: "\ebaf";
  }
  .icon-broken-link:before {
    content: "\ebb0";
  }
  .icon-info-1:before {
    content: "\ebb1";
  }
  .icon-check:before {
    content: "\ebb2";
  }
  .icon-audit-3:before {
    content: "\ebb3";
  }
  .icon-documents-1:before {
    content: "\ebb4";
  }
  .icon-search:before {
    content: "\ebb5";
  }
  .icon-audit-2:before {
    content: "\ebb6";
  }
  .icon-settings2:before {
    content: "\ebb7";
  }
  .icon-delete-1:before {
    content: "\ebb8";
  }
  .icon-edit-text:before {
    content: "\ebb9";
  }
  .icon-trash:before {
    content: "\ebba";
  }
  .icon-delete:before {
    content: "\ebbb";
  }
  .icon-security:before {
    content: "\ebbc";
  }
  .icon-subscription:before {
    content: "\ebbd";
  }
  .icon-installment:before {
    content: "\ebbe";
  }
  .icon-subscribe:before {
    content: "\ebbf";
  }
  .icon-info:before {
    content: "\ebc0";
  }
  .icon-Admin_Icon_01:before {
    content: "\ebc1";
  }
  .icon-Admin_Icon_02:before {
    content: "\ebc2";
  }
  .icon-arrow:before {
    content: "\ebc3";
  }
  .icon-down-arrow1:before {
    content: "\ebc4";
  }
  .icon-code:before {
    content: "\ebc5";
  }
  .icon-notification:before {
    content: "\ebc6";
  }
  .icon-documents:before {
    content: "\ebc7";
  }
  .icon-audit-1:before {
    content: "\ebc8";
  }
  .icon-audit:before {
    content: "\ebc9";
  }
  .icon-authentication:before {
    content: "\ebca";
  }
  .icon-menu5:before {
    content: "\ebcb";
  }
  .icon-right-arrow1:before {
    content: "\ebcc";
  }
  .icon-export:before {
    content: "\ebcd";
  }
  .icon-world-wide-web:before {
    content: "\ebce";
  }
  .icon-earth-globe:before {
    content: "\ebcf";
  }
  .icon-planet-earth-1:before {
    content: "\ebd0";
  }
  .icon-planet-earth:before {
    content: "\ebd1";
  }
  .icon-bar-graph:before {
    content: "\ebd2";
  }
  .icon-graph-svgrepo-com:before {
    content: "\ebd3";
  }
  .icon-reshot-icon-arrow-chevron-right-WDGHUKQ634:before {
    content: "\ebd4";
  }
  .icon-check-mark1:before {
    content: "\ebd5";
  }
  .icon-check-mark-icon:before {
    content: "\ebd6";
  }
  .icon-tick-svgrepo-com-1:before {
    content: "\ebd7";
  }
  .icon-star-fill2:before {
    content: "\112";
  }
  .icon-sorting2:before {
    content: "\113";
  }
  .icon-refresh24:before {
    content: "\114";
  }
  .icon-user-icon4:before {
    content: "\115";
  }
  .icon-download-file2:before {
    content: "\116";
  }
  .icon-interface2:before {
    content: "\117";
  }
  .icon-file-search4:before {
    content: "\118";
  }
  .icon-sort-down3:before {
    content: "\119";
  }
  .icon-sort-up2:before {
    content: "\11a";
  }
  .icon-sort-down12:before {
    content: "\11b";
  }
  .icon-replace2:before {
    content: "\11c";
  }
  .icon-user-network2:before {
    content: "\11d";
  }
  .icon-folder-network2:before {
    content: "\11e";
  }
  .icon-flow-chart34:before {
    content: "\11f";
  }
  .icon-flow-chart-dots4:before {
    content: "\120";
  }
  .icon-poll2:before {
    content: "\121";
  }
  .icon-eye32:before {
    content: "\122";
  }
  .icon-calendar-time2:before {
    content: "\123";
  }
  .icon-delete-button-lines2:before {
    content: "\124";
  }
  .icon-hourglass3:before {
    content: "\125";
  }
  .icon-right-arrow-fill2:before {
    content: "\126";
  }
  .icon-left-arrow-fill2:before {
    content: "\127";
  }
  .icon-down-arrow-fill2:before {
    content: "\128";
  }
  .icon-up-arrow-fill2:before {
    content: "\129";
  }
  .icon-session-time2:before {
    content: "\12a";
  }
  .icon-time-correct2:before {
    content: "\12b";
  }
  .icon-settings7:before {
    content: "\12c";
  }
  .icon-settings15:before {
    content: "\12d";
  }
  .icon-collapse2:before {
    content: "\12e";
  }
  .icon-expand3:before {
    content: "\12f";
  }
  .icon-angle-left2:before {
    content: "\130";
  }
  .icon-angle-right2:before {
    content: "\131";
  }
  .icon-safe-time4:before {
    content: "\132";
  }
  .icon-user-history2:before {
    content: "\133";
  }
  .icon-chart23:before {
    content: "\134";
  }
  .icon-file-network2:before {
    content: "\135";
  }
  .icon-flow-tree6:before {
    content: "\136";
  }
  .icon-chart12:before {
    content: "\137";
  }
  .icon-flow-tree14:before {
    content: "\138";
  }
  .icon-chart222:before {
    content: "\139";
  }
  .icon-flow-tree24:before {
    content: "\13a";
  }
  .icon-flow-tree-text2:before {
    content: "\13b";
  }
  .icon-text-file24:before {
    content: "\13c";
  }
  .icon-text-list-file22:before {
    content: "\13d";
  }
  .icon-user32:before {
    content: "\13e";
  }
  .icon-user-round2:before {
    content: "\13f";
  }
  .icon-search26:before {
    content: "\140";
  }
  .icon-search14:before {
    content: "\141";
  }
  .icon-search222:before {
    content: "\142";
  }
  .icon-search32:before {
    content: "\143";
  }
  .icon-bookmark3:before {
    content: "\144";
  }
  .icon-bookmark-empty2:before {
    content: "\145";
  }
  .icon-printer3:before {
    content: "\146";
  }
  .icon-open-folder3:before {
    content: "\147";
  }
  .icon-closed-folder2:before {
    content: "\148";
  }
  .icon-open-folder12:before {
    content: "\149";
  }
  .icon-calendar-peel2:before {
    content: "\14a";
  }
  .icon-menu22:before {
    content: "\14b";
  }
  .icon-chevron-left2:before {
    content: "\14c";
  }
  .icon-caret-up-two2:before {
    content: "\14d";
  }
  .icon-chevron-right3:before {
    content: "\14e";
  }
  .icon-chevron-up2:before {
    content: "\14f";
  }
  .icon-chevron-down3:before {
    content: "\150";
  }
  .icon-angle-down2:before {
    content: "\151";
  }
  .icon-left-arrow2:before {
    content: "\152";
  }
  .icon-right-arrow3:before {
    content: "\153";
  }
  .icon-down-arrow3:before {
    content: "\154";
  }
  .icon-medical-tablets2:before {
    content: "\155";
  }
  .icon-file-time5:before {
    content: "\156";
  }
  .icon-line-chart-dots5:before {
    content: "\157";
  }
  .icon-refresh-22:before {
    content: "\158";
  }
  .icon-line-chart-dots12:before {
    content: "\159";
  }
  .icon-refresh-file5:before {
    content: "\15a";
  }
  .icon-reverse2:before {
    content: "\15b";
  }
  .icon-filter3:before {
    content: "\15c";
  }
  .icon-text-file4:before {
    content: "\15d";
  }
  .icon-doc-file2:before {
    content: "\15e";
  }
  .icon-folders2:before {
    content: "\15f";
  }
  .icon-text-list-file6:before {
    content: "\160";
  }
  .icon-file-history5:before {
    content: "\161";
  }
  .icon-empty-file5:before {
    content: "\162";
  }
  .icon-code-file2:before {
    content: "\163";
  }
  .icon-text-file12:before {
    content: "\164";
  }
  .icon-text-list-file12:before {
    content: "\165";
  }
  .icon-empty-file12:before {
    content: "\166";
  }
  .icon-folder3:before {
    content: "\167";
  }
  .icon-books5:before {
    content: "\168";
  }
  .icon-books12:before {
    content: "\169";
  }
  .icon-network-folders2:before {
    content: "\16a";
  }
  .icon-calendar24:before {
    content: "\16b";
  }
  .icon-angle-up2:before {
    content: "\16c";
  }
  .icon-caret-left4:before {
    content: "\16d";
  }
  .icon-caret-right4:before {
    content: "\16e";
  }
  .icon-caret-down-two2:before {
    content: "\16f";
  }
  .icon-left-open-big2:before {
    content: "\e120";
  }
  .icon-up-open-big2:before {
    content: "\e121";
  }
  .icon-right-open-big2:before {
    content: "\e122";
  }
  .icon-down-open-big2:before {
    content: "\e123";
  }
  .icon-down-open4:before {
    content: "\e124";
  }
  .icon-left-open4:before {
    content: "\e125";
  }
  .icon-right-open4:before {
    content: "\e126";
  }
  .icon-up-open4:before {
    content: "\e127";
  }
  .icon-arrow-down4:before {
    content: "\e128";
  }
  .icon-arrow-left4:before {
    content: "\e129";
  }
  .icon-arrow-right4:before {
    content: "\e12a";
  }
  .icon-arrow-up4:before {
    content: "\e12b";
  }
  .icon-reload32:before {
    content: "\e12c";
  }
  .icon-chevron-up-12:before {
    content: "\e12d";
  }
  .icon-chevron-right-12:before {
    content: "\e12e";
  }
  .icon-chevron-left-12:before {
    content: "\e12f";
  }
  .icon-chevron-down-12:before {
    content: "\e130";
  }
  .icon-arrow-swap2:before {
    content: "\e131";
  }
  .icon-exchange4:before {
    content: "\e132";
  }
  .icon-angle-double-down2:before {
    content: "\e133";
  }
  .icon-download32:before {
    content: "\e134";
  }
  .icon-download-12:before {
    content: "\e135";
  }
  .icon-close-button2:before {
    content: "\e136";
  }
  .icon-close4:before {
    content: "\e137";
  }
  .icon-close-round4:before {
    content: "\e138";
  }
  .icon-cross-mark4:before {
    content: "\e139";
  }
  .icon-cancel4:before {
    content: "\e13a";
  }
  .icon-remove2:before {
    content: "\e13b";
  }
  .icon-lock4:before {
    content: "\e13c";
  }
  .icon-unlock-alt2:before {
    content: "\e13d";
  }
  .icon-user-outline3:before {
    content: "\e13e";
  }
  .icon-book2:before {
    content: "\e13f";
  }
  .icon-content-72:before {
    content: "\e140";
  }
  .icon-folder-o2:before {
    content: "\e141";
  }
  .icon-folder-open-o2:before {
    content: "\e142";
  }
  .icon-folder-open2:before {
    content: "\e143";
  }
  .icon-folder-12:before {
    content: "\e144";
  }
  .icon-file-close-alt2:before {
    content: "\e145";
  }
  .icon-file-open2:before {
    content: "\e146";
  }
  .icon-file-close2:before {
    content: "\e147";
  }
  .icon-file-alt2:before {
    content: "\e148";
  }
  .icon-draw2:before {
    content: "\e149";
  }
  .icon-editing2:before {
    content: "\e14a";
  }
  .icon-edit4:before {
    content: "\e14b";
  }
  .icon-trash-o2:before {
    content: "\e14c";
  }
  .icon-sort2:before {
    content: "\e14d";
  }
  .icon-times2:before {
    content: "\e14e";
  }
  .icon-close-12:before {
    content: "\e14f";
  }
  .icon-search-13:before {
    content: "\e150";
  }
  .icon-search-icon4:before {
    content: "\e151";
  }
  .icon-search-1-12:before {
    content: "\e152";
  }
  .icon-search-24:before {
    content: "\e153";
  }
  .icon-search-find2:before {
    content: "\e154";
  }
  .icon-id-card2:before {
    content: "\e155";
  }
  .icon-resume2:before {
    content: "\e156";
  }
  .icon-plus3:before {
    content: "\e157";
  }
  .icon-plus-circle5:before {
    content: "\e158";
  }
  .icon-plus-12:before {
    content: "\e159";
  }
  .icon-plus-circled2:before {
    content: "\e15a";
  }
  .icon-plus-square5:before {
    content: "\e15b";
  }
  .icon-menu-33:before {
    content: "\e15c";
  }
  .icon-list3:before {
    content: "\e15d";
  }
  .icon-menu-12:before {
    content: "\e15e";
  }
  .icon-bars22:before {
    content: "\e15f";
  }
  .icon-content-442:before {
    content: "\e160";
  }
  .icon-table2:before {
    content: "\e161";
  }
  .icon-add2:before {
    content: "\e162";
  }
  .icon-question2:before {
    content: "\e163";
  }
  .icon-folder22:before {
    content: "\e164";
  }
  .icon-folder-22:before {
    content: "\e165";
  }
  .icon-folder-1-14:before {
    content: "\e166";
  }
  .icon-pdf-file-format-symbol2:before {
    content: "\e167";
  }
  .icon-file-pdf-o22:before {
    content: "\e168";
  }
  .icon-file-word-o3:before {
    content: "\e169";
  }
  .icon-file-text-o5:before {
    content: "\e16a";
  }
  .icon-file-powerpoint-o2:before {
    content: "\e16b";
  }
  .icon-file-image-o3:before {
    content: "\e16c";
  }
  .icon-files-o4:before {
    content: "\e16d";
  }
  .icon-file-archive-o22:before {
    content: "\e16e";
  }
  .icon-file-code-o3:before {
    content: "\e16f";
  }
  .icon-file-excel-o22:before {
    content: "\e170";
  }
  .icon-file-o4:before {
    content: "\e171";
  }
  .icon-page-add2:before {
    content: "\e172";
  }
  .icon-file-add2:before {
    content: "\e173";
  }
  .icon-file-settings4:before {
    content: "\e174";
  }
  .icon-align-center2:before {
    content: "\e175";
  }
  .icon-files1:before {
    content: "\e176";
  }
  .icon-file-code2:before {
    content: "\e177";
  }
  .icon-file-binary2:before {
    content: "\e178";
  }
  .icon-file-pdf22:before {
    content: "\e179";
  }
  .icon-gallery2:before {
    content: "\e17a";
  }
  .icon-tool3:before {
    content: "\e17b";
  }
  .icon-paperclip12:before {
    content: "\e17c";
  }
  .icon-content-414:before {
    content: "\e17d";
  }
  .icon-content-432:before {
    content: "\e17e";
  }
  .icon-eye-12:before {
    content: "\e17f";
  }
  .icon-check-mark5:before {
    content: "\e180";
  }
  .icon-round-14:before {
    content: "\e181";
  }
  .icon-signs4:before {
    content: "\e182";
  }
  .icon-lock-12:before {
    content: "\e183";
  }
  .icon-unlock5:before {
    content: "\e184";
  }
  .icon-lock-22:before {
    content: "\e185";
  }
  .icon-unlocked2:before {
    content: "\e186";
  }
  .icon-user-12:before {
    content: "\e187";
  }
  .icon-man-people-streamline-user2:before {
    content: "\e188";
  }
  .icon-power2:before {
    content: "\e189";
  }
  .icon-power-12:before {
    content: "\e18a";
  }
  .icon-log-out4:before {
    content: "\e18b";
  }
  .icon-arrow-left-12:before {
    content: "\e18c";
  }
  .icon-arrows-12:before {
    content: "\e18d";
  }
  .icon-download-22:before {
    content: "\e18e";
  }
  .icon-arrow-circle-down-line2:before {
    content: "\e18f";
  }
  .icon-download-1-12:before {
    content: "\e190";
  }
  .icon-home-12:before {
    content: "\e191";
  }
  .icon-home-house-streamline2:before {
    content: "\e192";
  }
  .icon-home-button3:before {
    content: "\e193";
  }
  .icon-correct2:before {
    content: "\e194";
  }
  .icon-explore2:before {
    content: "\ebd8";
  }
  .icon-at2:before {
    content: "\ebd9";
  }
  .icon-user8:before {
    content: "\ebda";
  }
  .icon-hash3:before {
    content: "\ebdb";
  }
  .icon-office2:before {
    content: "\ebdc";
  }
  .icon-download5:before {
    content: "\ebdd";
  }
  .icon-email24:before {
    content: "\ebde";
  }
  .icon-call_split2:before {
    content: "\ebdf";
  }
  .icon-event_note2:before {
    content: "\ebe0";
  }
  .icon-pie-chart24:before {
    content: "\ebe1";
  }
  .icon-hash24:before {
    content: "\ebe2";
  }
  .icon-eye5:before {
    content: "\ebe3";
  }
  .icon-building-o2:before {
    content: "\ebe4";
  }
  .icon-view-tile5:before {
    content: "\ebe5";
  }
  .icon-view-list2:before {
    content: "\ebe6";
  }
  .icon-view-carousel4:before {
    content: "\ebe7";
  }
  .icon-library4:before {
    content: "\ebe8";
  }
  .icon-user-solid-circle2:before {
    content: "\ebe9";
  }
  .icon-user-add2:before {
    content: "\ebea";
  }
  .icon-user22:before {
    content: "\ebeb";
  }
  .icon-text-box2:before {
    content: "\ebec";
  }
  .icon-chart-pie3:before {
    content: "\ebed";
  }
  .icon-view-tile24:before {
    content: "\ebee";
  }
  .icon-mail22:before {
    content: "\ebef";
  }
  .icon-mail-envelope-closed3:before {
    content: "\ebf0";
  }
  .icon-mail-envelope-closed22:before {
    content: "\ebf1";
  }
  .icon-inbox-upload22:before {
    content: "\ebf2";
  }
  .icon-inbox-upload32:before {
    content: "\ebf3";
  }
  .icon-inbox-document-text3:before {
    content: "\ebf4";
  }
  .icon-inbox-document-text22:before {
    content: "\ebf5";
  }
  .icon-inbox-document2:before {
    content: "\ebf6";
  }
  .icon-inbox-download2:before {
    content: "\ebf7";
  }
  .icon-inbox-upload4:before {
    content: "\ebf8";
  }
  .icon-chart5:before {
    content: "\ebf9";
  }
  .icon-site-map2:before {
    content: "\ebfa";
  }
  .icon-alarm-clock2:before {
    content: "\ebfb";
  }
  .icon-justice2:before {
    content: "\ebfc";
  }
  .icon-chat4:before {
    content: "\ebfd";
  }
  .icon-retweet2:before {
    content: "\ebfe";
  }
  .icon-resize2:before {
    content: "\ebff";
  }
  .icon-upload22:before {
    content: "\ec00";
  }
  .icon-download42:before {
    content: "\ec01";
  }
  .icon-archive22:before {
    content: "\ec02";
  }
  .icon-arrow-minimise2:before {
    content: "\ec03";
  }
  .icon-vpn_key2:before {
    content: "\ec04";
  }
  .icon-group_add2:before {
    content: "\ec05";
  }
  .icon-account_balance2:before {
    content: "\ec06";
  }
  .icon-question_answer2:before {
    content: "\ec07";
  }
  .icon-compare_arrows2:before {
    content: "\ec08";
  }
  .icon-crop3:before {
    content: "\ec09";
  }
  .icon-edit-copy2:before {
    content: "\ec0a";
  }
  .icon-folder-download2:before {
    content: "\ec0b";
  }
  .icon-folder-upload2:before {
    content: "\ec0c";
  }
  .icon-notifications-outline2:before {
    content: "\ec0d";
  }
  .icon-conversation2:before {
    content: "\ec0e";
  }
  .icon-stats4:before {
    content: "\ec0f";
  }
  .icon-stats22:before {
    content: "\ec10";
  }
  .icon-loop-alt42:before {
    content: "\ec11";
  }
  .icon-image3:before {
    content: "\ec12";
  }
  .icon-library22:before {
    content: "\ec13";
  }
  .icon-file-text4:before {
    content: "\ec14";
  }
  .icon-profile22:before {
    content: "\ec15";
  }
  .icon-file-empty3:before {
    content: "\ec16";
  }
  .icon-files-empty3:before {
    content: "\ec17";
  }
  .icon-file-text22:before {
    content: "\ec18";
  }
  .icon-file-picture2:before {
    content: "\ec19";
  }
  .icon-clone2:before {
    content: "\ec1a";
  }
  .icon-file-archive-o3:before {
    content: "\ec1b";
  }
  .icon-file-zip2:before {
    content: "\ec1c";
  }
  .icon-copy3:before {
    content: "\ec1d";
  }
  .icon-paste3:before {
    content: "\ec1e";
  }
  .icon-arrows-h2:before {
    content: "\ec1f";
  }
  .icon-television2:before {
    content: "\ec20";
  }
  .icon-file-pdf-o4:before {
    content: "\ec21";
  }
  .icon-file-excel-o4:before {
    content: "\ec22";
  }
  .icon-data-viewer2:before {
    content: "\ec23";
  }
  .icon-mail12:before {
    content: "\ec24";
  }
  .icon-history2:before {
    content: "\ec25";
  }
  .icon-users5:before {
    content: "\ec26";
  }
  .icon-icon-import2:before {
    content: "\ec27";
  }
  .icon-alarm2:before {
    content: "\ec28";
  }
  .icon-in-alt2:before {
    content: "\ec29";
  }
  .icon-stopwatch2:before {
    content: "\ec2a";
  }
  .icon-calendar4:before {
    content: "\ec2b";
  }
  .icon-file-plus2:before {
    content: "\ec2c";
  }
  .icon-menu13:before {
    content: "\ec2d";
  }
  .icon-display2:before {
    content: "\ec2e";
  }
  .icon-menu10:before {
    content: "\ec2f";
  }
  .icon-download23:before {
    content: "\ec30";
  }
  .icon-upload4:before {
    content: "\ec31";
  }
  .icon-download222:before {
    content: "\ec32";
  }
  .icon-upload32:before {
    content: "\ec33";
  }
  .icon-enlarge22:before {
    content: "\ec34";
  }
  .icon-shrink22:before {
    content: "\ec35";
  }
  .icon-cog2:before {
    content: "\ec36";
  }
  .icon-pie-chart5:before {
    content: "\ec37";
  }
  .icon-clipboard5:before {
    content: "\ec38";
  }
  .icon-menu34:before {
    content: "\ec39";
  }
  .icon-menu42:before {
    content: "\ec3a";
  }
  .icon-flag4:before {
    content: "\ec3b";
  }
  .icon-eye22:before {
    content: "\ec3c";
  }
  .icon-warning2:before {
    content: "\ec3d";
  }
  .icon-notification24:before {
    content: "\ec3e";
  }
  .icon-cancel-circle2:before {
    content: "\ec3f";
  }
  .icon-loop22:before {
    content: "\ec40";
  }
  .icon-crop22:before {
    content: "\ec41";
  }
  .icon-pagebreak2:before {
    content: "\ec42";
  }
  .icon-file-pdf3:before {
    content: "\ec43";
  }
  .icon-file-word2:before {
    content: "\ec44";
  }
  .icon-file-excel2:before {
    content: "\ec45";
  }
  .icon-star-fill3:before {
    content: "\170";
  }
  .icon-sorting3:before {
    content: "\171";
  }
  .icon-refresh25:before {
    content: "\172";
  }
  .icon-user-icon5:before {
    content: "\173";
  }
  .icon-download-file3:before {
    content: "\174";
  }
  .icon-interface3:before {
    content: "\175";
  }
  .icon-file-search5:before {
    content: "\176";
  }
  .icon-sort-down4:before {
    content: "\177";
  }
  .icon-sort-up3:before {
    content: "\178";
  }
  .icon-sort-down13:before {
    content: "\179";
  }
  .icon-replace3:before {
    content: "\17a";
  }
  .icon-user-network3:before {
    content: "\17b";
  }
  .icon-folder-network3:before {
    content: "\17c";
  }
  .icon-flow-chart35:before {
    content: "\17d";
  }
  .icon-flow-chart-dots5:before {
    content: "\17e";
  }
  .icon-poll3:before {
    content: "\17f";
  }
  .icon-eye33:before {
    content: "\180";
  }
  .icon-calendar-time3:before {
    content: "\181";
  }
  .icon-delete-button-lines3:before {
    content: "\182";
  }
  .icon-hourglass4:before {
    content: "\183";
  }
  .icon-right-arrow-fill3:before {
    content: "\184";
  }
  .icon-left-arrow-fill3:before {
    content: "\185";
  }
  .icon-down-arrow-fill3:before {
    content: "\186";
  }
  .icon-up-arrow-fill3:before {
    content: "\187";
  }
  .icon-session-time3:before {
    content: "\188";
  }
  .icon-time-correct3:before {
    content: "\189";
  }
  .icon-settings8:before {
    content: "\18a";
  }
  .icon-settings16:before {
    content: "\18b";
  }
  .icon-collapse3:before {
    content: "\18c";
  }
  .icon-expand4:before {
    content: "\18d";
  }
  .icon-angle-left3:before {
    content: "\18e";
  }
  .icon-angle-right3:before {
    content: "\18f";
  }
  .icon-safe-time5:before {
    content: "\190";
  }
  .icon-user-history3:before {
    content: "\191";
  }
  .icon-chart24:before {
    content: "\192";
  }
  .icon-file-network3:before {
    content: "\193";
  }
  .icon-flow-tree7:before {
    content: "\194";
  }
  .icon-chart13:before {
    content: "\195";
  }
  .icon-flow-tree15:before {
    content: "\196";
  }
  .icon-chart223:before {
    content: "\197";
  }
  .icon-flow-tree25:before {
    content: "\198";
  }
  .icon-flow-tree-text3:before {
    content: "\199";
  }
  .icon-text-file25:before {
    content: "\19a";
  }
  .icon-text-list-file23:before {
    content: "\19b";
  }
  .icon-user33:before {
    content: "\19c";
  }
  .icon-user-round3:before {
    content: "\19d";
  }
  .icon-search27:before {
    content: "\19e";
  }
  .icon-search15:before {
    content: "\19f";
  }
  .icon-search223:before {
    content: "\1a0";
  }
  .icon-search33:before {
    content: "\1a1";
  }
  .icon-bookmark4:before {
    content: "\1a2";
  }
  .icon-bookmark-empty3:before {
    content: "\1a3";
  }
  .icon-printer4:before {
    content: "\1a4";
  }
  .icon-open-folder4:before {
    content: "\1a5";
  }
  .icon-closed-folder3:before {
    content: "\1a6";
  }
  .icon-open-folder13:before {
    content: "\1a7";
  }
  .icon-calendar-peel3:before {
    content: "\1a8";
  }
  .icon-menu23:before {
    content: "\1a9";
  }
  .icon-chevron-left3:before {
    content: "\1aa";
  }
  .icon-caret-up-two3:before {
    content: "\1ab";
  }
  .icon-chevron-right4:before {
    content: "\1ac";
  }
  .icon-chevron-up3:before {
    content: "\1ad";
  }
  .icon-chevron-down4:before {
    content: "\1ae";
  }
  .icon-angle-down3:before {
    content: "\1af";
  }
  .icon-left-arrow3:before {
    content: "\1b0";
  }
  .icon-right-arrow4:before {
    content: "\1b1";
  }
  .icon-down-arrow4:before {
    content: "\1b2";
  }
  .icon-medical-tablets3:before {
    content: "\1b3";
  }
  .icon-file-time6:before {
    content: "\1b4";
  }
  .icon-line-chart-dots6:before {
    content: "\1b5";
  }
  .icon-refresh-23:before {
    content: "\1b6";
  }
  .icon-line-chart-dots13:before {
    content: "\1b7";
  }
  .icon-refresh-file6:before {
    content: "\1b8";
  }
  .icon-reverse3:before {
    content: "\1b9";
  }
  .icon-filter4:before {
    content: "\1ba";
  }
  .icon-text-file5:before {
    content: "\1bb";
  }
  .icon-doc-file3:before {
    content: "\1bc";
  }
  .icon-folders3:before {
    content: "\1bd";
  }
  .icon-text-list-file7:before {
    content: "\1be";
  }
  .icon-file-history6:before {
    content: "\1bf";
  }
  .icon-empty-file6:before {
    content: "\1c0";
  }
  .icon-code-file3:before {
    content: "\1c1";
  }
  .icon-text-file13:before {
    content: "\1c2";
  }
  .icon-text-list-file13:before {
    content: "\1c3";
  }
  .icon-empty-file13:before {
    content: "\1c4";
  }
  .icon-folder4:before {
    content: "\1c5";
  }
  .icon-books6:before {
    content: "\1c6";
  }
  .icon-books13:before {
    content: "\1c7";
  }
  .icon-network-folders3:before {
    content: "\1c8";
  }
  .icon-calendar25:before {
    content: "\1c9";
  }
  .icon-angle-up3:before {
    content: "\1ca";
  }
  .icon-caret-left5:before {
    content: "\1cb";
  }
  .icon-caret-right5:before {
    content: "\1cc";
  }
  .icon-caret-down-two3:before {
    content: "\1cd";
  }
  .icon-left-open-big3:before {
    content: "\e195";
  }
  .icon-up-open-big3:before {
    content: "\e196";
  }
  .icon-right-open-big3:before {
    content: "\e197";
  }
  .icon-down-open-big3:before {
    content: "\e198";
  }
  .icon-down-open5:before {
    content: "\e199";
  }
  .icon-left-open5:before {
    content: "\e19a";
  }
  .icon-right-open5:before {
    content: "\e19b";
  }
  .icon-up-open5:before {
    content: "\e19c";
  }
  .icon-arrow-down5:before {
    content: "\e19d";
  }
  .icon-arrow-left5:before {
    content: "\e19e";
  }
  .icon-arrow-right5:before {
    content: "\e19f";
  }
  .icon-arrow-up5:before {
    content: "\e1a0";
  }
  .icon-reload33:before {
    content: "\e1a1";
  }
  .icon-chevron-up-13:before {
    content: "\e1a2";
  }
  .icon-chevron-right-13:before {
    content: "\e1a3";
  }
  .icon-chevron-left-13:before {
    content: "\e1a4";
  }
  .icon-chevron-down-13:before {
    content: "\e1a5";
  }
  .icon-arrow-swap3:before {
    content: "\e1a6";
  }
  .icon-exchange5:before {
    content: "\e1a7";
  }
  .icon-angle-double-down3:before {
    content: "\e1a8";
  }
  .icon-download33:before {
    content: "\e1a9";
  }
  .icon-download-13:before {
    content: "\e1aa";
  }
  .icon-close-button3:before {
    content: "\e1ab";
  }
  .icon-close5:before {
    content: "\e1ac";
  }
  .icon-close-round5:before {
    content: "\e1ad";
  }
  .icon-cross-mark5:before {
    content: "\e1ae";
  }
  .icon-cancel5:before {
    content: "\e1af";
  }
  .icon-remove3:before {
    content: "\e1b0";
  }
  .icon-lock5:before {
    content: "\e1b1";
  }
  .icon-unlock-alt3:before {
    content: "\e1b2";
  }
  .icon-user-outline4:before {
    content: "\e1b3";
  }
  .icon-book3:before {
    content: "\e1b4";
  }
  .icon-content-73:before {
    content: "\e1b5";
  }
  .icon-folder-o3:before {
    content: "\e1b6";
  }
  .icon-folder-open-o3:before {
    content: "\e1b7";
  }
  .icon-folder-open3:before {
    content: "\e1b8";
  }
  .icon-folder-13:before {
    content: "\e1b9";
  }
  .icon-file-close-alt3:before {
    content: "\e1ba";
  }
  .icon-file-open3:before {
    content: "\e1bb";
  }
  .icon-file-close3:before {
    content: "\e1bc";
  }
  .icon-file-alt3:before {
    content: "\e1bd";
  }
  .icon-draw3:before {
    content: "\e1be";
  }
  .icon-editing3:before {
    content: "\e1bf";
  }
  .icon-edit5:before {
    content: "\e1c0";
  }
  .icon-trash-o3:before {
    content: "\e1c1";
  }
  .icon-sort3:before {
    content: "\e1c2";
  }
  .icon-times3:before {
    content: "\e1c3";
  }
  .icon-close-13:before {
    content: "\e1c4";
  }
  .icon-search-14:before {
    content: "\e1c5";
  }
  .icon-search-icon5:before {
    content: "\e1c6";
  }
  .icon-search-1-13:before {
    content: "\e1c7";
  }
  .icon-search-25:before {
    content: "\e1c8";
  }
  .icon-search-find3:before {
    content: "\e1c9";
  }
  .icon-id-card3:before {
    content: "\e1ca";
  }
  .icon-resume3:before {
    content: "\e1cb";
  }
  .icon-plus4:before {
    content: "\e1cc";
  }
  .icon-plus-circle6:before {
    content: "\e1cd";
  }
  .icon-plus-13:before {
    content: "\e1ce";
  }
  .icon-plus-circled3:before {
    content: "\e1cf";
  }
  .icon-plus-square6:before {
    content: "\e1d0";
  }
  .icon-menu-34:before {
    content: "\e1d1";
  }
  .icon-list4:before {
    content: "\e1d2";
  }
  .icon-menu-13:before {
    content: "\e1d3";
  }
  .icon-bars23:before {
    content: "\e1d4";
  }
  .icon-content-443:before {
    content: "\e1d5";
  }
  .icon-table3:before {
    content: "\e1d6";
  }
  .icon-add3:before {
    content: "\e1d7";
  }
  .icon-question3:before {
    content: "\e1d8";
  }
  .icon-folder23:before {
    content: "\e1d9";
  }
  .icon-folder-23:before {
    content: "\e1da";
  }
  .icon-folder-1-15:before {
    content: "\e1db";
  }
  .icon-pdf-file-format-symbol3:before {
    content: "\e1dc";
  }
  .icon-file-pdf-o23:before {
    content: "\e1dd";
  }
  .icon-file-word-o4:before {
    content: "\e1de";
  }
  .icon-file-text-o6:before {
    content: "\e1df";
  }
  .icon-file-powerpoint-o3:before {
    content: "\e1e0";
  }
  .icon-file-image-o4:before {
    content: "\e1e1";
  }
  .icon-files-o5:before {
    content: "\e1e2";
  }
  .icon-file-archive-o23:before {
    content: "\e1e3";
  }
  .icon-file-code-o4:before {
    content: "\e1e4";
  }
  .icon-file-excel-o23:before {
    content: "\e1e5";
  }
  .icon-file-o5:before {
    content: "\e1e6";
  }
  .icon-page-add3:before {
    content: "\e1e7";
  }
  .icon-file-add3:before {
    content: "\e1e8";
  }
  .icon-file-settings5:before {
    content: "\e1e9";
  }
  .icon-align-center3:before {
    content: "\e1ea";
  }
  .icon-files3:before {
    content: "\e1eb";
  }
  .icon-file-code3:before {
    content: "\e1ec";
  }
  .icon-file-binary3:before {
    content: "\e1ed";
  }
  .icon-file-pdf23:before {
    content: "\e1ee";
  }
  .icon-gallery3:before {
    content: "\e1ef";
  }
  .icon-tool4:before {
    content: "\e1f0";
  }
  .icon-paperclip13:before {
    content: "\e1f1";
  }
  .icon-content-415:before {
    content: "\e1f2";
  }
  .icon-content-433:before {
    content: "\e1f3";
  }
  .icon-eye-13:before {
    content: "\e1f4";
  }
  .icon-check-mark6:before {
    content: "\e1f5";
  }
  .icon-round-15:before {
    content: "\e1f6";
  }
  .icon-signs5:before {
    content: "\e1f7";
  }
  .icon-lock-13:before {
    content: "\e1f8";
  }
  .icon-unlock6:before {
    content: "\e1f9";
  }
  .icon-lock-23:before {
    content: "\e1fa";
  }
  .icon-unlocked3:before {
    content: "\e1fb";
  }
  .icon-user-13:before {
    content: "\e1fc";
  }
  .icon-man-people-streamline-user3:before {
    content: "\e1fd";
  }
  .icon-power3:before {
    content: "\e1fe";
  }
  .icon-power-13:before {
    content: "\e1ff";
  }
  .icon-log-out5:before {
    content: "\e200";
  }
  .icon-arrow-left-13:before {
    content: "\e201";
  }
  .icon-arrows-13:before {
    content: "\e202";
  }
  .icon-download-23:before {
    content: "\e203";
  }
  .icon-arrow-circle-down-line3:before {
    content: "\e204";
  }
  .icon-download-1-13:before {
    content: "\e205";
  }
  .icon-home-13:before {
    content: "\e206";
  }
  .icon-home-house-streamline3:before {
    content: "\e207";
  }
  .icon-home-button4:before {
    content: "\e208";
  }
  .icon-correct3:before {
    content: "\e209";
  }
  .icon-explore3:before {
    content: "\ec46";
  }
  .icon-at3:before {
    content: "\ec47";
  }
  .icon-user9:before {
    content: "\ec48";
  }
  .icon-hash4:before {
    content: "\ec49";
  }
  .icon-office3:before {
    content: "\ec4a";
  }
  .icon-download6:before {
    content: "\ec4b";
  }
  .icon-email25:before {
    content: "\ec4c";
  }
  .icon-call_split3:before {
    content: "\ec4d";
  }
  .icon-event_note3:before {
    content: "\ec4e";
  }
  .icon-pie-chart25:before {
    content: "\ec4f";
  }
  .icon-hash25:before {
    content: "\ec50";
  }
  .icon-eye6:before {
    content: "\ec51";
  }
  .icon-building-o3:before {
    content: "\ec52";
  }
  .icon-view-tile6:before {
    content: "\ec53";
  }
  .icon-view-list3:before {
    content: "\ec54";
  }
  .icon-view-carousel5:before {
    content: "\ec55";
  }
  .icon-library5:before {
    content: "\ec56";
  }
  .icon-user-solid-circle3:before {
    content: "\ec57";
  }
  .icon-user-add3:before {
    content: "\ec58";
  }
  .icon-user23:before {
    content: "\ec59";
  }
  .icon-text-box3:before {
    content: "\ec5a";
  }
  .icon-chart-pie4:before {
    content: "\ec5b";
  }
  .icon-view-tile25:before {
    content: "\ec5c";
  }
  .icon-mail23:before {
    content: "\ec5d";
  }
  .icon-mail-envelope-closed4:before {
    content: "\ec5e";
  }
  .icon-mail-envelope-closed23:before {
    content: "\ec5f";
  }
  .icon-inbox-upload23:before {
    content: "\ec60";
  }
  .icon-inbox-upload33:before {
    content: "\ec61";
  }
  .icon-inbox-document-text4:before {
    content: "\ec62";
  }
  .icon-inbox-document-text23:before {
    content: "\ec63";
  }
  .icon-inbox-document3:before {
    content: "\ec64";
  }
  .icon-inbox-download3:before {
    content: "\ec65";
  }
  .icon-inbox-upload5:before {
    content: "\ec66";
  }
  .icon-chart6:before {
    content: "\ec67";
  }
  .icon-site-map3:before {
    content: "\ec68";
  }
  .icon-alarm-clock3:before {
    content: "\ec69";
  }
  .icon-justice3:before {
    content: "\ec6a";
  }
  .icon-chat5:before {
    content: "\ec6b";
  }
  .icon-retweet3:before {
    content: "\ec6c";
  }
  .icon-resize3:before {
    content: "\ec6d";
  }
  .icon-upload23:before {
    content: "\ec6e";
  }
  .icon-download43:before {
    content: "\ec6f";
  }
  .icon-archive23:before {
    content: "\ec70";
  }
  .icon-arrow-minimise3:before {
    content: "\ec71";
  }
  .icon-vpn_key3:before {
    content: "\ec72";
  }
  .icon-group_add3:before {
    content: "\ec73";
  }
  .icon-account_balance3:before {
    content: "\ec74";
  }
  .icon-question_answer3:before {
    content: "\ec75";
  }
  .icon-compare_arrows3:before {
    content: "\ec76";
  }
  .icon-crop4:before {
    content: "\ec77";
  }
  .icon-edit-copy3:before {
    content: "\ec78";
  }
  .icon-folder-download3:before {
    content: "\ec79";
  }
  .icon-folder-upload3:before {
    content: "\ec7a";
  }
  .icon-notifications-outline3:before {
    content: "\ec7b";
  }
  .icon-conversation3:before {
    content: "\ec7c";
  }
  .icon-stats5:before {
    content: "\ec7d";
  }
  .icon-stats23:before {
    content: "\ec7e";
  }
  .icon-loop-alt43:before {
    content: "\ec7f";
  }
  .icon-image4:before {
    content: "\ec80";
  }
  .icon-library23:before {
    content: "\ec81";
  }
  .icon-file-text5:before {
    content: "\ec82";
  }
  .icon-profile23:before {
    content: "\ec83";
  }
  .icon-file-empty4:before {
    content: "\ec84";
  }
  .icon-files-empty4:before {
    content: "\ec85";
  }
  .icon-file-text23:before {
    content: "\ec86";
  }
  .icon-file-picture3:before {
    content: "\ec87";
  }
  .icon-clone3:before {
    content: "\ec88";
  }
  .icon-file-archive-o4:before {
    content: "\ec89";
  }
  .icon-file-zip3:before {
    content: "\ec8a";
  }
  .icon-copy4:before {
    content: "\ec8b";
  }
  .icon-paste4:before {
    content: "\ec8c";
  }
  .icon-arrows-h3:before {
    content: "\ec8d";
  }
  .icon-television3:before {
    content: "\ec8e";
  }
  .icon-file-pdf-o5:before {
    content: "\ec8f";
  }
  .icon-file-excel-o5:before {
    content: "\ec90";
  }
  .icon-data-viewer3:before {
    content: "\ec91";
  }
  .icon-mail13:before {
    content: "\ec92";
  }
  .icon-history3:before {
    content: "\ec93";
  }
  .icon-users6:before {
    content: "\ec94";
  }
  .icon-icon-import3:before {
    content: "\ec95";
  }
  .icon-alarm3:before {
    content: "\ec96";
  }
  .icon-in-alt3:before {
    content: "\ec97";
  }
  .icon-stopwatch3:before {
    content: "\ec98";
  }
  .icon-calendar5:before {
    content: "\ec99";
  }
  .icon-file-plus3:before {
    content: "\ec9a";
  }
  .icon-menu14:before {
    content: "\ec9b";
  }
  .icon-display3:before {
    content: "\ec9c";
  }
  .icon-menu15:before {
    content: "\ec9d";
  }
  .icon-download24:before {
    content: "\ec9e";
  }
  .icon-upload5:before {
    content: "\ec9f";
  }
  .icon-download223:before {
    content: "\eca0";
  }
  .icon-upload33:before {
    content: "\eca1";
  }
  .icon-enlarge23:before {
    content: "\eca2";
  }
  .icon-shrink23:before {
    content: "\eca3";
  }
  .icon-cog3:before {
    content: "\eca4";
  }
  .icon-pie-chart6:before {
    content: "\eca5";
  }
  .icon-clipboard6:before {
    content: "\eca6";
  }
  .icon-menu35:before {
    content: "\eca7";
  }
  .icon-menu43:before {
    content: "\eca8";
  }
  .icon-flag5:before {
    content: "\eca9";
  }
  .icon-eye23:before {
    content: "\ecaa";
  }
  .icon-warning3:before {
    content: "\ecab";
  }
  .icon-notification25:before {
    content: "\ecac";
  }
  .icon-cancel-circle3:before {
    content: "\ecad";
  }
  .icon-loop23:before {
    content: "\ecae";
  }
  .icon-crop23:before {
    content: "\ecaf";
  }
  .icon-pagebreak3:before {
    content: "\ecb0";
  }
  .icon-file-pdf4:before {
    content: "\ecb1";
  }
  .icon-file-word3:before {
    content: "\ecb2";
  }
  .icon-file-excel3:before {
    content: "\ecb3";
  }
  .icon-star-fill:before {
    content: "\21";
  }
  .icon-sorting:before {
    content: "\22";
  }
  .icon-refresh2:before {
    content: "\23";
  }
  .icon-user-icon:before {
    content: "\24";
  }
  .icon-download-file:before {
    content: "\25";
  }
  .icon-interface:before {
    content: "\26";
  }
  .icon-file-search:before {
    content: "\27";
  }
  .icon-sort-down:before {
    content: "\28";
  }
  .icon-sort-up:before {
    content: "\29";
  }
  .icon-sort-down1:before {
    content: "\2a";
  }
  .icon-replace:before {
    content: "\2b";
  }
  .icon-user-network:before {
    content: "\2c";
  }
  .icon-folder-network:before {
    content: "\2d";
  }
  .icon-flow-chart3:before {
    content: "\2e";
  }
  .icon-flow-chart-dots:before {
    content: "\2f";
  }
  .icon-poll:before {
    content: "\30";
  }
  .icon-eye3:before {
    content: "\31";
  }
  .icon-calendar-time:before {
    content: "\32";
  }
  .icon-delete-button-lines:before {
    content: "\33";
  }
  .icon-hourglass:before {
    content: "\34";
  }
  .icon-right-arrow-fill:before {
    content: "\35";
  }
  .icon-left-arrow-fill:before {
    content: "\36";
  }
  .icon-down-arrow-fill:before {
    content: "\37";
  }
  .icon-up-arrow-fill:before {
    content: "\38";
  }
  .icon-session-time:before {
    content: "\39";
  }
  .icon-time-correct:before {
    content: "\3a";
  }
  .icon-settings:before {
    content: "\3b";
  }
  .icon-settings1:before {
    content: "\3c";
  }
  .icon-collapse:before {
    content: "\3d";
  }
  .icon-expand:before {
    content: "\3e";
  }
  .icon-angle-left:before {
    content: "\3f";
  }
  .icon-angle-right:before {
    content: "\40";
  }
  .icon-safe-time:before {
    content: "\41";
  }
  .icon-user-history:before {
    content: "\42";
  }
  .icon-chart2:before {
    content: "\43";
  }
  .icon-file-network:before {
    content: "\44";
  }
  .icon-flow-tree:before {
    content: "\45";
  }
  .icon-chart1:before {
    content: "\46";
  }
  .icon-flow-tree1:before {
    content: "\47";
  }
  .icon-chart22:before {
    content: "\48";
  }
  .icon-flow-tree2:before {
    content: "\49";
  }
  .icon-flow-tree-text:before {
    content: "\4a";
  }
  .icon-text-file2:before {
    content: "\4b";
  }
  .icon-text-list-file2:before {
    content: "\4c";
  }
  .icon-user3:before {
    content: "\4d";
  }
  .icon-user-round:before {
    content: "\4e";
  }
  .icon-search2:before {
    content: "\4f";
  }
  .icon-search1:before {
    content: "\50";
  }
  .icon-search22:before {
    content: "\51";
  }
  .icon-search3:before {
    content: "\52";
  }
  .icon-bookmark:before {
    content: "\53";
  }
  .icon-bookmark-empty:before {
    content: "\54";
  }
  .icon-printer:before {
    content: "\55";
  }
  .icon-open-folder:before {
    content: "\56";
  }
  .icon-closed-folder:before {
    content: "\57";
  }
  .icon-open-folder1:before {
    content: "\58";
  }
  .icon-calendar-peel:before {
    content: "\59";
  }
  .icon-menu2:before {
    content: "\5a";
  }
  .icon-chevron-left:before {
    content: "\5b";
  }
  .icon-caret-up-two:before {
    content: "\5c";
  }
  .icon-chevron-right:before {
    content: "\5d";
  }
  .icon-chevron-up:before {
    content: "\5e";
  }
  .icon-chevron-down:before {
    content: "\5f";
  }
  .icon-angle-down:before {
    content: "\60";
  }
  .icon-left-arrow:before {
    content: "\61";
  }
  .icon-right-arrow:before {
    content: "\62";
  }
  .icon-down-arrow:before {
    content: "\63";
  }
  .icon-medical-tablets:before {
    content: "\64";
  }
  .icon-file-time:before {
    content: "\65";
  }
  .icon-line-chart-dots:before {
    content: "\66";
  }
  .icon-refresh-2:before {
    content: "\67";
  }
  .icon-line-chart-dots1:before {
    content: "\68";
  }
  .icon-refresh-file:before {
    content: "\69";
  }
  .icon-reverse:before {
    content: "\6a";
  }
  .icon-filter:before {
    content: "\6b";
  }
  .icon-text-file:before {
    content: "\6c";
  }
  .icon-doc-file:before {
    content: "\6d";
  }
  .icon-folders:before {
    content: "\6e";
  }
  .icon-text-list-file:before {
    content: "\6f";
  }
  .icon-file-history:before {
    content: "\70";
  }
  .icon-empty-file:before {
    content: "\71";
  }
  .icon-code-file:before {
    content: "\72";
  }
  .icon-text-file1:before {
    content: "\73";
  }
  .icon-text-list-file1:before {
    content: "\74";
  }
  .icon-empty-file1:before {
    content: "\75";
  }
  .icon-folder:before {
    content: "\76";
  }
  .icon-books:before {
    content: "\77";
  }
  .icon-books1:before {
    content: "\78";
  }
  .icon-network-folders:before {
    content: "\79";
  }
  .icon-calendar2:before {
    content: "\7a";
  }
  .icon-angle-up:before {
    content: "\7b";
  }
  .icon-caret-left:before {
    content: "\7c";
  }
  .icon-caret-right:before {
    content: "\7d";
  }
  .icon-caret-down-two:before {
    content: "\7e";
  }
  .icon-left-open-big:before {
    content: "\e000";
  }
  .icon-up-open-big:before {
    content: "\e001";
  }
  .icon-right-open-big:before {
    content: "\e002";
  }
  .icon-down-open-big:before {
    content: "\e003";
  }
  .icon-down-open:before {
    content: "\e004";
  }
  .icon-left-open:before {
    content: "\e005";
  }
  .icon-right-open:before {
    content: "\e006";
  }
  .icon-up-open:before {
    content: "\e007";
  }
  .icon-arrow-down:before {
    content: "\e008";
  }
  .icon-arrow-left:before {
    content: "\e009";
  }
  .icon-arrow-right:before {
    content: "\e00a";
  }
  .icon-arrow-up:before {
    content: "\e00b";
  }
  .icon-reload3:before {
    content: "\e00c";
  }
  .icon-chevron-up-1:before {
    content: "\e00d";
  }
  .icon-chevron-right-1:before {
    content: "\e00e";
  }
  .icon-chevron-left-1:before {
    content: "\e00f";
  }
  .icon-chevron-down-1:before {
    content: "\e010";
  }
  .icon-arrow-swap:before {
    content: "\e011";
  }
  .icon-exchange:before {
    content: "\e012";
  }
  .icon-angle-double-down:before {
    content: "\e013";
  }
  .icon-download3:before {
    content: "\e014";
  }
  .icon-download-1:before {
    content: "\e015";
  }
  .icon-close-button:before {
    content: "\e016";
  }
  .icon-close:before {
    content: "\e017";
  }
  .icon-close-round:before {
    content: "\e018";
  }
  .icon-cross-mark:before {
    content: "\e019";
  }
  .icon-cancel:before {
    content: "\e01a";
  }
  .icon-remove:before {
    content: "\e01b";
  }
  .icon-lock:before {
    content: "\e01c";
  }
  .icon-unlock-alt:before {
    content: "\e01d";
  }
  .icon-user-outline:before {
    content: "\e01e";
  }
  .icon-book:before {
    content: "\e01f";
  }
  .icon-content-7:before {
    content: "\e020";
  }
  .icon-folder-o:before {
    content: "\e021";
  }
  .icon-folder-open-o:before {
    content: "\e022";
  }
  .icon-folder-open:before {
    content: "\e023";
  }
  .icon-folder-1:before {
    content: "\e024";
  }
  .icon-file-close-alt:before {
    content: "\e025";
  }
  .icon-file-open:before {
    content: "\e026";
  }
  .icon-file-close:before {
    content: "\e027";
  }
  .icon-file-alt:before {
    content: "\e028";
  }
  .icon-draw:before {
    content: "\e029";
  }
  .icon-editing:before {
    content: "\e02a";
  }
  .icon-edit:before {
    content: "\e02b";
  }
  .icon-trash-o:before {
    content: "\e02c";
  }
  .icon-sort:before {
    content: "\e02d";
  }
  .icon-times:before {
    content: "\e02e";
  }
  .icon-close-1:before {
    content: "\e02f";
  }
  .icon-search-1:before {
    content: "\e030";
  }
  .icon-search-icon:before {
    content: "\e031";
  }
  .icon-search-1-1:before {
    content: "\e032";
  }
  .icon-search-2:before {
    content: "\e033";
  }
  .icon-search-find:before {
    content: "\e034";
  }
  .icon-id-card:before {
    content: "\e035";
  }
  .icon-resume:before {
    content: "\e036";
  }
  .icon-plus:before {
    content: "\e037";
  }
  .icon-plus-circle:before {
    content: "\e038";
  }
  .icon-plus-1:before {
    content: "\e039";
  }
  .icon-plus-circled:before {
    content: "\e03a";
  }
  .icon-plus-square:before {
    content: "\e03b";
  }
  .icon-menu-3:before {
    content: "\e03c";
  }
  .icon-list:before {
    content: "\e03d";
  }
  .icon-menu-1:before {
    content: "\e03e";
  }
  .icon-bars2:before {
    content: "\e03f";
  }
  .icon-content-44:before {
    content: "\e040";
  }
  .icon-table:before {
    content: "\e041";
  }
  .icon-add:before {
    content: "\e042";
  }
  .icon-question:before {
    content: "\e043";
  }
  .icon-folder2:before {
    content: "\e044";
  }
  .icon-folder-2:before {
    content: "\e045";
  }
  .icon-folder-1-1:before {
    content: "\e046";
  }
  .icon-pdf-file-format-symbol:before {
    content: "\e047";
  }
  .icon-file-pdf-o2:before {
    content: "\e048";
  }
  .icon-file-word-o:before {
    content: "\e049";
  }
  .icon-file-text-o:before {
    content: "\e04a";
  }
  .icon-file-powerpoint-o:before {
    content: "\e04b";
  }
  .icon-file-image-o:before {
    content: "\e04c";
  }
  .icon-files-o:before {
    content: "\e04d";
  }
  .icon-file-archive-o2:before {
    content: "\e04e";
  }
  .icon-file-code-o:before {
    content: "\e04f";
  }
  .icon-file-excel-o2:before {
    content: "\e050";
  }
  .icon-file-o:before {
    content: "\e051";
  }
  .icon-page-add:before {
    content: "\e052";
  }
  .icon-file-add:before {
    content: "\e053";
  }
  .icon-file-settings:before {
    content: "\e054";
  }
  .icon-align-center:before {
    content: "\e055";
  }
  .icon-files:before {
    content: "\e056";
  }
  .icon-file-code:before {
    content: "\e057";
  }
  .icon-file-binary:before {
    content: "\e058";
  }
  .icon-file-pdf2:before {
    content: "\e059";
  }
  .icon-gallery:before {
    content: "\e05a";
  }
  .icon-tool:before {
    content: "\e05b";
  }
  .icon-paperclip1:before {
    content: "\e05c";
  }
  .icon-content-41:before {
    content: "\e05d";
  }
  .icon-content-43:before {
    content: "\e05e";
  }
  .icon-eye-1:before {
    content: "\e05f";
  }
  .icon-check-mark:before {
    content: "\e060";
  }
  .icon-round-1:before {
    content: "\e061";
  }
  .icon-signs:before {
    content: "\e062";
  }
  .icon-lock-1:before {
    content: "\e063";
  }
  .icon-unlock:before {
    content: "\e064";
  }
  .icon-lock-2:before {
    content: "\e065";
  }
  .icon-unlocked:before {
    content: "\e066";
  }
  .icon-user-1:before {
    content: "\e067";
  }
  .icon-man-people-streamline-user:before {
    content: "\e068";
  }
  .icon-power:before {
    content: "\e069";
  }
  .icon-power-1:before {
    content: "\e06a";
  }
  .icon-log-out:before {
    content: "\e06b";
  }
  .icon-arrow-left-1:before {
    content: "\e06c";
  }
  .icon-arrows-1:before {
    content: "\e06d";
  }
  .icon-download-2:before {
    content: "\e06e";
  }
  .icon-arrow-circle-down-line:before {
    content: "\e06f";
  }
  .icon-download-1-1:before {
    content: "\e070";
  }
  .icon-home-1:before {
    content: "\e071";
  }
  .icon-home-house-streamline:before {
    content: "\e072";
  }
  .icon-home-button:before {
    content: "\e073";
  }
  .icon-correct:before {
    content: "\e074";
  }
  .icon-explore:before {
    content: "\e900";
  }
  .icon-at:before {
    content: "\e901";
  }
  .icon-user:before {
    content: "\e902";
  }
  .icon-hash:before {
    content: "\e903";
  }
  .icon-office:before {
    content: "\e904";
  }
  .icon-download:before {
    content: "\e905";
  }
  .icon-email2:before {
    content: "\e906";
  }
  .icon-call_split:before {
    content: "\e907";
  }
  .icon-event_note:before {
    content: "\e908";
  }
  .icon-pie-chart2:before {
    content: "\e909";
  }
  .icon-hash2:before {
    content: "\e90a";
  }
  .icon-eye:before {
    content: "\e90b";
  }
  .icon-building-o:before {
    content: "\e90c";
  }
  .icon-view-tile:before {
    content: "\e90d";
  }
  .icon-view-list:before {
    content: "\e90e";
  }
  .icon-view-carousel:before {
    content: "\e90f";
  }
  .icon-library:before {
    content: "\e910";
  }
  .icon-user-solid-circle:before {
    content: "\e911";
  }
  .icon-user-add:before {
    content: "\e912";
  }
  .icon-user2:before {
    content: "\e913";
  }
  .icon-text-box:before {
    content: "\e914";
  }
  .icon-chart-pie:before {
    content: "\e915";
  }
  .icon-view-tile2:before {
    content: "\e916";
  }
  .icon-mail2:before {
    content: "\e917";
  }
  .icon-mail-envelope-closed:before {
    content: "\e918";
  }
  .icon-mail-envelope-closed2:before {
    content: "\e919";
  }
  .icon-inbox-upload2:before {
    content: "\e91a";
  }
  .icon-inbox-upload3:before {
    content: "\e91b";
  }
  .icon-inbox-document-text:before {
    content: "\e91c";
  }
  .icon-inbox-document-text2:before {
    content: "\e91d";
  }
  .icon-inbox-document:before {
    content: "\e91e";
  }
  .icon-inbox-download:before {
    content: "\e91f";
  }
  .icon-inbox-upload:before {
    content: "\e920";
  }
  .icon-chart:before {
    content: "\e921";
  }
  .icon-site-map:before {
    content: "\e922";
  }
  .icon-alarm-clock:before {
    content: "\e923";
  }
  .icon-justice:before {
    content: "\e924";
  }
  .icon-chat:before {
    content: "\e925";
  }
  .icon-retweet:before {
    content: "\e926";
  }
  .icon-resize:before {
    content: "\e927";
  }
  .icon-upload2:before {
    content: "\e928";
  }
  .icon-download4:before {
    content: "\e929";
  }
  .icon-archive2:before {
    content: "\e92a";
  }
  .icon-arrow-minimise:before {
    content: "\e92b";
  }
  .icon-vpn_key:before {
    content: "\e92c";
  }
  .icon-group_add:before {
    content: "\e92d";
  }
  .icon-account_balance:before {
    content: "\e92e";
  }
  .icon-question_answer:before {
    content: "\e92f";
  }
  .icon-compare_arrows:before {
    content: "\e930";
  }
  .icon-crop:before {
    content: "\e931";
  }
  .icon-edit-copy:before {
    content: "\e932";
  }
  .icon-folder-download:before {
    content: "\e933";
  }
  .icon-folder-upload:before {
    content: "\e934";
  }
  .icon-notifications-outline:before {
    content: "\e935";
  }
  .icon-conversation:before {
    content: "\e936";
  }
  .icon-stats:before {
    content: "\e937";
  }
  .icon-stats2:before {
    content: "\e938";
  }
  .icon-loop-alt4:before {
    content: "\e939";
  }
  .icon-image:before {
    content: "\e93a";
  }
  .icon-library2:before {
    content: "\e93b";
  }
  .icon-file-text:before {
    content: "\e93c";
  }
  .icon-profile2:before {
    content: "\e93d";
  }
  .icon-file-empty:before {
    content: "\e93e";
  }
  .icon-files-empty:before {
    content: "\e93f";
  }
  .icon-file-text2:before {
    content: "\e940";
  }
  .icon-file-picture:before {
    content: "\e941";
  }
  .icon-clone:before {
    content: "\e942";
  }
  .icon-file-archive-o:before {
    content: "\e943";
  }
  .icon-file-zip:before {
    content: "\e944";
  }
  .icon-copy:before {
    content: "\e945";
  }
  .icon-paste:before {
    content: "\e946";
  }
  .icon-arrows-h:before {
    content: "\e947";
  }
  .icon-television:before {
    content: "\e948";
  }
  .icon-file-pdf-o:before {
    content: "\e949";
  }
  .icon-file-excel-o:before {
    content: "\e94a";
  }
  .icon-data-viewer:before {
    content: "\e94b";
  }
  .icon-mail1:before {
    content: "\e94c";
  }
  .icon-history:before {
    content: "\e94d";
  }
  .icon-users:before {
    content: "\e94e";
  }
  .icon-icon-import:before {
    content: "\e94f";
  }
  .icon-alarm:before {
    content: "\e950";
  }
  .icon-in-alt:before {
    content: "\e951";
  }
  .icon-stopwatch:before {
    content: "\e952";
  }
  .icon-calendar:before {
    content: "\e953";
  }
  .icon-file-plus:before {
    content: "\e954";
  }
  .icon-menu1:before {
    content: "\e955";
  }
  .icon-display:before {
    content: "\e956";
  }
  .icon-menu:before {
    content: "\e957";
  }
  .icon-download2:before {
    content: "\e960";
  }
  .icon-upload:before {
    content: "\e961";
  }
  .icon-download22:before {
    content: "\e962";
  }
  .icon-upload3:before {
    content: "\e963";
  }
  .icon-enlarge2:before {
    content: "\e98b";
  }
  .icon-shrink2:before {
    content: "\e98c";
  }
  .icon-cog:before {
    content: "\e994";
  }
  .icon-pie-chart:before {
    content: "\e99a";
  }
  .icon-clipboard:before {
    content: "\e9b8";
  }
  .icon-menu3:before {
    content: "\e9bf";
  }
  .icon-menu4:before {
    content: "\e9c0";
  }
  .icon-flag:before {
    content: "\e9cc";
  }
  .icon-eye2:before {
    content: "\e9ce";
  }
  .icon-warning:before {
    content: "\ea07";
  }
  .icon-notification2:before {
    content: "\ea08";
  }
  .icon-cancel-circle:before {
    content: "\ea0d";
  }
  .icon-loop2:before {
    content: "\ea2d";
  }
  .icon-crop2:before {
    content: "\ea57";
  }
  .icon-pagebreak:before {
    content: "\ea6e";
  }
  .icon-file-pdf:before {
    content: "\eadf";
  }
  .icon-file-word:before {
    content: "\eae1";
  }
  .icon-file-excel:before {
    content: "\eae2";
  }
  .icon-tool-5:before {
    content: "\7f";
  }
  .icon-test-tube:before {
    content: "\80";
  }
  .icon-testtube1:before {
    content: "\81";
  }
  .icon-textlines28:before {
    content: "\82";
  }
  .icon-symbol:before {
    content: "\83";
  }
  .icon-briefcase65:before {
    content: "\e075";
  }
  .icon-file-image-o1:before {
    content: "\e076";
  }
  .icon-file-pdf-o1:before {
    content: "\e077";
  }
  .icon-file-text-o1:before {
    content: "\e078";
  }
  .icon-file-word-o1:before {
    content: "\e079";
  }
  .icon-file-excel-o1:before {
    content: "\e07a";
  }
  .icon-file-code-o1:before {
    content: "\e07b";
  }
  .icon-paper-plane:before {
    content: "\e07c";
  }
  .icon-paper-plane-o:before {
    content: "\e07d";
  }
  .icon-hourglass1:before {
    content: "\e07e";
  }
  .icon-networking:before {
    content: "\e07f";
  }
  .icon-tool1:before {
    content: "\e08c";
  }
  .icon-packing:before {
    content: "\e958";
  }
  .icon-archive:before {
    content: "\e959";
  }
  .icon-briefcase:before {
    content: "\e95a";
  }
  .icon-note:before {
    content: "\e95b";
  }
  .icon-doctor1:before {
    content: "\e95c";
  }
  .icon-trend:before {
    content: "\e95d";
  }
  .icon-bar-chart:before {
    content: "\e95e";
  }
  .icon-doctor:before {
    content: "\e95f";
  }
  .icon-lab:before {
    content: "\e964";
  }
  .icon-activity:before {
    content: "\e965";
  }
  .icon-align-justify:before {
    content: "\e966";
  }
  .icon-arrow-down-left:before {
    content: "\e967";
  }
  .icon-arrow-down-right:before {
    content: "\e968";
  }
  .icon-at-sign:before {
    content: "\e969";
  }
  .icon-award:before {
    content: "\e96a";
  }
  .icon-bell:before {
    content: "\e96b";
  }
  .icon-book-open:before {
    content: "\e96c";
  }
  .icon-bookmark1:before {
    content: "\e96d";
  }
  .icon-box:before {
    content: "\e96e";
  }
  .icon-briefcase1:before {
    content: "\e96f";
  }
  .icon-calendar1:before {
    content: "\e970";
  }
  .icon-check1:before {
    content: "\e971";
  }
  .icon-check-circle:before {
    content: "\e972";
  }
  .icon-check-square:before {
    content: "\e973";
  }
  .icon-chevron-down1:before {
    content: "\e974";
  }
  .icon-chevron-right1:before {
    content: "\e975";
  }
  .icon-chevrons-right:before {
    content: "\e976";
  }
  .icon-chrome:before {
    content: "\e977";
  }
  .icon-circle:before {
    content: "\e978";
  }
  .icon-clipboard1:before {
    content: "\e979";
  }
  .icon-clock:before {
    content: "\e97a";
  }
  .icon-cloud-drizzle:before {
    content: "\e97b";
  }
  .icon-code1:before {
    content: "\e97c";
  }
  .icon-codepen:before {
    content: "\e97d";
  }
  .icon-codesandbox:before {
    content: "\e97e";
  }
  .icon-command:before {
    content: "\e97f";
  }
  .icon-compass:before {
    content: "\e980";
  }
  .icon-copy1:before {
    content: "\e981";
  }
  .icon-database:before {
    content: "\e982";
  }
  .icon-disc:before {
    content: "\e983";
  }
  .icon-dollar-sign:before {
    content: "\e984";
  }
  .icon-edit1:before {
    content: "\e985";
  }
  .icon-external-link:before {
    content: "\e986";
  }
  .icon-eye1:before {
    content: "\e987";
  }
  .icon-eye-off:before {
    content: "\e988";
  }
  .icon-feather:before {
    content: "\e989";
  }
  .icon-file-text1:before {
    content: "\e98a";
  }
  .icon-folder-minus:before {
    content: "\e98d";
  }
  .icon-folder-plus:before {
    content: "\e98e";
  }
  .icon-framer:before {
    content: "\e98f";
  }
  .icon-gift:before {
    content: "\e990";
  }
  .icon-globe:before {
    content: "\e991";
  }
  .icon-grid:before {
    content: "\e992";
  }
  .icon-hard-drive:before {
    content: "\e993";
  }
  .icon-home:before {
    content: "\e995";
  }
  .icon-image1:before {
    content: "\e996";
  }
  .icon-inbox:before {
    content: "\e997";
  }
  .icon-layers:before {
    content: "\e998";
  }
  .icon-link1:before {
    content: "\e999";
  }
  .icon-link-2:before {
    content: "\e99b";
  }
  .icon-map:before {
    content: "\e99c";
  }
  .icon-map-pin:before {
    content: "\e99d";
  }
  .icon-maximize:before {
    content: "\e99e";
  }
  .icon-maximize-2:before {
    content: "\e99f";
  }
  .icon-minimize-2:before {
    content: "\e9a0";
  }
  .icon-more-horizontal:before {
    content: "\e9a1";
  }
  .icon-more-vertical:before {
    content: "\e9a2";
  }
  .icon-move:before {
    content: "\e9a3";
  }
  .icon-package:before {
    content: "\e9a4";
  }
  .icon-paperclip:before {
    content: "\e9a5";
  }
  .icon-pen-tool:before {
    content: "\e9a6";
  }
  .icon-pie-chart1:before {
    content: "\e9a7";
  }
  .icon-printer1:before {
    content: "\e9a8";
  }
  .icon-refresh-cw:before {
    content: "\e9a9";
  }
  .icon-rotate-ccw:before {
    content: "\e9aa";
  }
  .icon-save:before {
    content: "\e9ab";
  }
  .icon-send:before {
    content: "\e9ac";
  }
  .icon-settings3:before {
    content: "\e9ad";
  }
  .icon-share-2:before {
    content: "\e9ae";
  }
  .icon-shopping-bag:before {
    content: "\e9af";
  }
  .icon-sidebar:before {
    content: "\e9b0";
  }
  .icon-slash:before {
    content: "\e9b1";
  }
  .icon-sliders:before {
    content: "\e9b2";
  }
  .icon-speaker:before {
    content: "\e9b3";
  }
  .icon-star:before {
    content: "\e9b4";
  }
  .icon-tag:before {
    content: "\e9b5";
  }
  .icon-thumbs-up:before {
    content: "\e9b6";
  }
  .icon-toggle-left:before {
    content: "\e9b7";
  }
  .icon-toggle-right:before {
    content: "\e9b9";
  }
  .icon-trello:before {
    content: "\e9ba";
  }
  .icon-trending-up:before {
    content: "\e9bb";
  }
  .icon-unlock1:before {
    content: "\e9bc";
  }
  .icon-user-check:before {
    content: "\e9bd";
  }
  .icon-user-plus:before {
    content: "\e9be";
  }
  .icon-x-circle:before {
    content: "\e9c1";
  }
  .icon-zap:before {
    content: "\e9c2";
  }
  .icon-pin:before {
    content: "\e9c3";
  }
  .icon-image11:before {
    content: "\e9c4";
  }
  .icon-trophy:before {
    content: "\e9c5";
  }
  .icon-chart3:before {
    content: "\e9c6";
  }
  .icon-switch:before {
    content: "\e9c7";
  }
  .icon-flat:before {
    content: "\e9c8";
  }
  .icon-data:before {
    content: "\e9c9";
  }
  .icon-users1:before {
    content: "\e9ca";
  }
  .icon-box1:before {
    content: "\e9cb";
  }
  .icon-bank:before {
    content: "\e9cd";
  }
  .icon-bulb:before {
    content: "\e9cf";
  }
  .icon-diamond:before {
    content: "\e9d0";
  }
  .icon-clock1:before {
    content: "\e9d1";
  }
  .icon-paperplane:before {
    content: "\e9d2";
  }
  .icon-study:before {
    content: "\e9d3";
  }
  .icon-fire:before {
    content: "\e9d4";
  }
  .icon-clip:before {
    content: "\e9d5";
  }
  .icon-wallet:before {
    content: "\e9d6";
  }
  .icon-delete1:before {
    content: "\e9d7";
  }
  .icon-filter1:before {
    content: "\e9d8";
  }
  .icon-flag2:before {
    content: "\e9d9";
  }
  .icon-mail:before {
    content: "\e9da";
  }
  .icon-printer11:before {
    content: "\e9db";
  }
  .icon-scissors:before {
    content: "\e9dc";
  }
  .icon-share-21:before {
    content: "\e9dd";
  }
  .icon-slash1:before {
    content: "\e9de";
  }
  .icon-umbrella:before {
    content: "\e9df";
  }
  .icon-watch:before {
    content: "\e9e0";
  }
  .icon-library1:before {
    content: "\e9e1";
  }
  .icon-quotes-left:before {
    content: "\e9e2";
  }
  .icon-quotes-right:before {
    content: "\e9e3";
  }
  .icon-user1:before {
    content: "\e9e4";
  }
  .icon-send1:before {
    content: "\e9e5";
  }
  .icon-settings11:before {
    content: "\e9e6";
  }
  .icon-shield:before {
    content: "\e9e7";
  }
  .icon-shield-off:before {
    content: "\e9e8";
  }
  .icon-shopping-bag1:before {
    content: "\e9e9";
  }
  .icon-shuffle:before {
    content: "\e9ea";
  }
  .icon-smile:before {
    content: "\e9eb";
  }
  .icon-tool11:before {
    content: "\e9ec";
  }
  .icon-trash1:before {
    content: "\e9ed";
  }
  .icon-trash-2:before {
    content: "\e9ee";
  }
  .icon-trending-down:before {
    content: "\e9ef";
  }
  .icon-trending-up1:before {
    content: "\e9f0";
  }
  .icon-unlock11:before {
    content: "\e9f1";
  }
  .icon-user11:before {
    content: "\e9f2";
  }
  .icon-user-check1:before {
    content: "\e9f3";
  }
  .icon-user-minus:before {
    content: "\e9f4";
  }
  .icon-user-plus1:before {
    content: "\e9f5";
  }
  .icon-user-x:before {
    content: "\e9f6";
  }
  .icon-zoom-in:before {
    content: "\e9f7";
  }
  .icon-zoom-out:before {
    content: "\e9f8";
  }
  .icon-earth:before {
    content: "\e9f9";
  }
  