button.MuiButtonBase-root.MuiButton-root{
    border: 1px solid transparent;
    padding: 0.4rem 0.8rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    font-weight: 400;
    text-transform: none;
    font-family: $font-family;
    box-shadow: none;


    &.MuiButton-containedPrimary {  
        background:$primary-color !important;
        border-color: $primary-color;
        color: $white;
        &:hover{
            background:darken($primary-color, 5%);
        }
    }
    &.MuiButton-outlined{
        background:$white;
            border-color: $gray-400;
            color: $gray-900;
            &:hover{
                background:darken($white, 5%);
            }
    }
    &.MuiButton-outlinedPrimary {
  
        background:$white;
        border-color: $primary-color;
        color:$primary-color;
        padding: 0.4rem 0.6rem;
        &:hover{
            background:darken($white, 5%);
        }
        > svg{
            height: 16px;
            margin-right: 2px;
        }
    }
    
}

button.MuiButtonBase-root.MuiButton-root .Mui-disabled{
    color: rgba(0, 0, 0, 0.26) !important;
    border: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.btn{
    padding: 5px;
    box-shadow: none;
    border-width: 1px;
    border-radius: 3px;
    &:hover{
        cursor: pointer;
    }
    &.btn-primary {
        background:$primary-color;
        border-color: $primary-color-dark;
        color: $primary-light;
    }
    &.btn-secondary {
        background:$white;
        border-color: $gray-600;
        color: $gray-900;
    }
}
button.MuiButtonBase-root.MuiButton-root + button.MuiButtonBase-root.MuiButton-root {
    margin-left: 16px;
}
.MuiButtonBase-root.bulkactions {
    border: 1px solid $gray-600;
    padding: 0.4rem 0.2rem;
    font-size: 0.8rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    font-weight: 400;
    text-transform: none;
    font-family: $font-family;  
}